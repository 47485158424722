import React, { useEffect, useState } from "react";
import Arrow_Pointing_Left from "../../images/desktop_back.svg";
import Frame2 from "../../images/car_dealer_black.svg";
import Account_black from "../../images/private_seller_black.svg";
import Location2 from "../../images/location_city _black.svg";
import Heart from "../../images/102_103_106_heart_saved_listing.svg";
import Love from "../../images/heart_black.svg";
import colorHeert from "../../images/heart_y.svg";
import userSmall from "../../images/private_seller_grey.svg";
import smallDealer from "../../images/car_dealer_grey.svg";
import grayHeart from "../../images/heart_grey.svg";
import drctin from "../../images/104_directions.svg";
import yr from "../../images/yr.svg";
import dsl from "../../images/dsl.svg";
import milg from "../../images/milg.svg";
import auto from "../../images/auto.svg";
import lcn from "../../images/lcn.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BASE_URL, lan } from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { setPreProduct } from "../../Redux/ActionCreater";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function SellerDetails({
  data,
  setSellerPost,
  sellerPost,
  adData,
  username,
  saveSeller,
  saveDealerData,
  activePost,
  soldPost,
}) {
  const navigate = useNavigate();
  let [idx,setIdx]=useState(0);
  const dispatch = useDispatch();
  let [mode, setMode] = useState("active");
  const options = {
    loop: true,
    margin: 0,
    items: 1,
    autoplay: true,
    autoplaySpeed: 1200,
    rewind: false,
    lazyLoad: true,
    // navRewind: true,
    autoplayTimeout: 3000,
    navSpeed: 2000,
    dragEndSpeed: 2000,
    dots: false,
    nav: false,
  };
  const { preProduct } = useSelector(
    (state) => ({
      preProduct: state.dataReducer.preProduct,
    }),
    shallowEqual
  );

  const clickProduct = (e, item) => {
    e.preventDefault();
    let arr = preProduct;
    arr.push(`/${username}`);
    dispatch(setPreProduct(arr));
    // navigate(`/${username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`);
    if(item.make){
      navigate(`/${username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
    }else{
      navigate(`/${username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const wishlistClick = async (e, item) => {
    e.preventDefault();
    let token = localStorage.getItem("access_token");
    if (token == null || token == "") {
      toast.dismiss();
      return toast.error(lan.login_continue_msg);
    }
    try {
      await axios({
        url: BASE_URL + "/api/wishlist/add-remove",
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          post_id: item._id,
          is_favourite: !item.wishlistData.is_favourite,
        },
      }).then((res) => {
        if (res.data.status === 200) {
          sellerPost.map((i, idx) => {
            if (i._id === item._id) {
              let arr = sellerPost;
              arr[idx].wishlistData.is_favourite =
                !item.wishlistData.is_favourite;
              setSellerPost([...arr]);
            }
          });
          item.wishlistData.is_favourite
            ? toast.success("Product added to your wishlist")
            : toast.success("Product removed from wishlist");
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  let clickDetailsBack = (e) => {
    e.preventDefault();
    if (preProduct.length === 0) {
      navigate("/");
    } else {
      let idx = preProduct.length - 1;
      navigate(preProduct[idx]);
      // navigate(`/post-details/${preProduct[idx]}`);
      let arr = preProduct;
      arr.splice(-1);
      dispatch(setPreProduct(arr));
    }
  };
  let gotolink=(url)=>{
    url&&window.open(url, "_blank", "noreferrer");
  }

  useEffect(()=>{
    if(adData.length>0){
      let idx=Math.floor(Math.random() * adData.length)
      setIdx(idx)
    }
  },[adData])
  return (
    <>
      <div class="listingHed CAR_DEALER">
        <div class="container">
          <div class="listingHedinr">
            <a
              href=""
              onClick={(e) => {
                clickDetailsBack(e);
              }}
            >
              <img src={Arrow_Pointing_Left} alt="" />
            </a>
            <div>
              <h1>
                {data.username ? data.username : data.full_name}
                <em>
                  <img
                    src={
                      data?.seller_type == "private_seller"
                        ? Account_black
                        : Frame2
                    }
                    alt=""
                  />
                </em>
              </h1>
              <strong>
                {data.seller_type == "private_seller"
                  ? lan.private_seller
                  : lan.car_dealer.toUpperCase()}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div class="listingDtils CAR_DEALERSldrSec">
        <div class="container">
          {data.seller_type === "private_seller" && (
            <div class="CAR_DEALERSldrads" onClick={()=>gotolink(adData[idx].link)}>
              {/* <h4>Mobile Ad</h4> */}
              {adData && adData[0]?.image && (
                <img
                  src={`${BASE_URL}/uploads/banner/${adData[idx]?.mobile_image}`}
                  alt=""
                />
              )}
            </div>
          )}
          {data.seller_type !== "private_seller" && (
            <div class="row">
              <div class="col-lg-8">
                <div class="listingDtilslft">
                  <div class="listingDtilslftSldr">
                    <OwlCarousel
                      class="owl-carousel owl-theme"
                      {...options}
                      loop
                      dots
                    >
                      {data.images&&data.images.length>0?data.images.map(item=>{
return <div class="item" >
<div class="carDetilsImge">
	<img src={`${BASE_URL}/uploads/user/${item}`}/>
</div>
</div>
						}):<div class="item" >
						<div class="carDetilsImge" style={{minHeight:'402px'}}>
							<h4>NO IMAGES</h4>
						</div>
					</div>}
                      
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 moblhid">
                <div class="listingDtilsRt">
                  <div class="detailedDescrip">
                    <h4 style={{ fontWeight: 600 }}>
                      <em>{lan.car_dealer_desc}</em>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div class="ctrgyList">
            <ul>
              <li>
                <i className="saveDelar">
                  <img src={Location2} alt="" />
                </i>
                <em>{data?.city_id?.city_name}</em>
              </li>
              <li>
                {data.seller_type !== "private_seller" ? (
                  <ul>
                    <li>
                      <a
                        href={data?.direction_link}
                        target="_blank"
                        style={{ width: "42px" }}
                      >
                        <i className="saveDelar">
                          <img src={drctin} alt="" />
                        </i>
                      </a>
                      {/* <em>{lan.directions}</em> */}
                    </li>
                    <li onClick={saveSeller}>
                      <i className="saveDelar" style={{marginTop:0}}>
                        <img
                          src={
                            saveDealerData?.is_favourite === true ? Love : Heart
                          }
                          alt=""
                        />
                      </i>
                      {/* <em>{saveDealerData?.is_favourite === true ?"PRODAVAC SPREMLJEN":"SPREMI PRODAVCA"}</em> */}
                    </li>
                  </ul>
                ) : (
                  <li onClick={saveSeller}>
                    <i className="saveDelar" style={{marginTop:0}}>
                      <img
                        src={
                          saveDealerData?.is_favourite === true ? Love : Heart
                        }
                        alt=""
                      />
                    </i>
                    {/* <em>{saveDealerData?.is_favourite === true ?"PRODAVAC SPREMLJEN":"SPREMI PRODAVCA"}</em> */}
                  </li>
                )}
              </li>
            </ul>
          </div>
          {/* {data.seller_type !== "private_seller"&&<div class="ctrgyList"><ul>
							<li >
								<i className='saveDelar'><img src={addrss} alt="" /></i>
								<em>{lan.address}</em>
							</li>
							<li>
								<i className='saveDelar'><img src={drctin} alt="" /></i>
								<em>{lan.directions}</em>
							</li>
						</ul></div>} */}
          {data.seller_type !== "private_seller" && (
            <div class="col-lg-4 dskHide" style={{ marginBottom: "18px" }}>
              <div class="listingDtilsRt">
                <div class="detailedDescrip">
                  <h4 style={{ fontWeight: 600 }}>
                    <em>{lan.car_dealer_desc}</em>
                  </h4>
                </div>
              </div>
            </div>
          )}

          {data?.seller_type === "private_seller" && (
            <div class="desktopAds">
              <div class="container" style={{padding:0}}>
                <div
                  class="desktopAdspnl"
                  style={{ marginTop: "20px" }} onClick={()=>gotolink(adData[idx].link)}
                >
                  {/* <h1>Desktop Ad</h1> */}
                  {adData && adData[0]?.image && (
                    <img
                      src={`${BASE_URL}/uploads/banner/${adData[idx]?.image}`}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div class="row activeListing">
            <div class="col-lg-8 col-md-6 col-6">
              <div
                style={{ background: mode === "active" ? "#202629" : "#fff",cursor:"pointer" }}
                class="ACTIVE_LISTINGS"
                onClick={() => {
                  setMode("active");
                  setSellerPost(activePost);
                }}
              >
                <h3 style={{color:mode==="active"?"#fff":"#989898"}}>{lan.active_listings}</h3>
                {/* <i><img src={FilledCircle} alt="" /></i> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-6">
              <div
                style={{ background: mode === "finished" ? "#202629" : "#fff",cursor:"pointer" }}
                class="selerListinghed"
                onClick={() => {
                  setMode("finished");
                  setSellerPost(soldPost);
                }}
              >
                <h3 style={{color:mode==="finished"?"#fff":"#989898"}}>{lan.finish_listings}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="productSec list-view-filter">
        <div className="container">
          <div className="row">
            {sellerPost &&
              sellerPost.length > 0 &&
              sellerPost.map((item) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="productbx">
                      <div className="productbxtop">
                        <div className="productbxtopimg">
                          <a href="#url" className="userSmall">
                            <img
                              src={
                                item.user.seller_type === "private_seller"
                                  ? userSmall
                                  : smallDealer
                              }
                              alt
                            />
                          </a>
                          {item.image && JSON.parse(item.image).length > 0 && (
                            <img
                              onClick={(e) => {
                                clickProduct(e, item);
                              }}
                              src={`${BASE_URL}/uploads/post/${
                                JSON.parse(item.image)[0]
                              }`}
                              alt=""
                            />
                          )}
                          <a
                            href=""
                            onClick={(e) => {
                              wishlistClick(e, item);
                            }}
                            class="grayHeart"
                          >
                            {item.wishlistData?.is_favourite ? (
                              <img src={colorHeert} alt="" />
                            ) : (
                              <img src={grayHeart} alt="" />
                            )}
                          </a>
                          {item.selling_rent === "Rent" && (
                            <h5 className="rentBtn">RENT</h5>
                          )}
                          {item.new_used === "Novo" && (
                            <h5 className="rentBtn newBtn">NOVO</h5>
                          )}
                        </div>
                        <div className="productbxtoprtlst">
                          <ul>
                            <li>
                              <span>
                                <img src={yr} />
                              </span>
                              {item.year}
                            </li>
                            <li>
                              <span>
                                <img src={dsl} />
                              </span>
                              {item.fuel?item.fuel:"BENZIN"}
                            </li>
                            <li>
                              <span>
                                <img src={milg} />
                              </span>
                              {item.mileage}
                            </li>
                            <li className="trnmbl">
                              <span>
                                <img src={auto} />
                              </span>
                              {item.transmission}
                            </li>
                            <li>
                              <span>
                                <img src={lcn} />
                              </span>
                              {item.location?.city_name}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="productbxbtm"
                        onClick={(e) => {
                          clickProduct(e, item);
                        }}
                      >
                        <h3>{item.name}</h3>
                        <strong>
                          <span>KM</span> {item.price}
                        </strong>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerDetails;
