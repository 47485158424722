import React, { useEffect, useRef, useState } from 'react'
import userSmall from "../../images/private_seller_grey.svg"
import smallDealer from "../../images/car_dealer_grey.svg"
import grayHeart from "../../images/heart_grey.svg"
import colorHeert from "../../images/heart_y.svg"
import listView from "../../images/list.svg"
import gridView from "../../images/grid.svg"
import yr from "../../images/yr.svg"
import dsl from "../../images/dsl.svg"
import milg from "../../images/milg.svg"
import auto from "../../images/auto.svg"
import lcn from "../../images/lcn.svg"
import close_01 from "../../images/close_01.svg"
import { toast } from 'react-toastify'
import axios from 'axios'
import { BASE_URL, lan } from '../../config'
import SortSec from './SortSec'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getPostData, getUserData, setAddListStatus, setCurrentProduct, setListingMode, setMode, setMore, setPostData, setSearchdata, setloading } from '../../Redux/ActionCreater'
import { useNavigate } from 'react-router-dom'
import $ from "jquery";

function ProductSec() {
	const navigate = useNavigate();
	const [products, setProducts] = useState("");
	const [focus, setFocus] = useState(true);
	const dispatch = useDispatch();
	let [user, setUser] = useState("");
	let [searchData, setSeachdata] = useState("");

	const { postData,currentProduct,listingMode,sortField,sortvalue,searchValue,hasMore,pageNo,filters} = useSelector(
		(state) => ({
			postData: state.dataReducer.postData,
			currentProduct: state.dataReducer.currentProduct,
			listingMode: state.dataReducer.listingMode,
			sortField: state.dataReducer.sortField,
			searchValue: state.dataReducer.searchValue,
			sortvalue: state.dataReducer.sortvalue,
			hasMore: state.dataReducer.hasMore,
			pageNo: state.dataReducer.pageNo,
			filters: state.dataReducer.filters,
		}),
		shallowEqual
	);
	let elementRef=useRef(null)
	let searchRef=useRef(null)

	const logOut = () => {
		// setUserData("");
		dispatch(setMode(""));
		dispatch(getUserData(""));
		localStorage.removeItem("login_data");
		localStorage.removeItem("access_token");
		toast.success(lan.login_continue_msg);
		dispatch(getPostData({ preData: [] }));
		dispatch(setCurrentProduct(""));
		navigate("/");
	}

	const wishlistClick = async (e, item) => {
		e.preventDefault();
		let token = localStorage.getItem("access_token");
		if (token == null || token == "") {
			toast.dismiss();
			return toast.error(lan.login_continue_msg)
		}
		try {
			await axios({
				url: BASE_URL + "/api/wishlist/add-remove",
				method: "POST",
				headers: {
					authorization: token
				},
				data: {
					"post_id": item._id,
					"is_favourite": !item.wishlistData.is_favourite,

				},
			}).then(res => {
				if(res.data.status===401){
                    logOut()
                }
				if (res.data.status === 200) {
					postData.map((i, idx) => {
						if (i._id === item._id) {
							let arr = postData
							arr[idx].wishlistData.is_favourite = !item.wishlistData.is_favourite
							dispatch(setPostData([...arr]))
						}
					})
					toast.dismiss();
					item.wishlistData.is_favourite ? toast.success(lan.wishlist_add_msg) :
						toast.success(lan.wishlist_remove_msg);
				} else {
					toast.error(res.data.message);
				}
			})
		} catch (error) {
			console.log(error)
			if (error.response?.status === 401) {
				logOut()
			}
		}
	}
	useEffect(() => {
		let data = localStorage.getItem("login_data");
		if (data != null || data != "") {
			setUser(JSON.parse(data))
		}
		// fetchData()
		setSeachdata(searchValue)
		
		
		if(currentProduct===""){
			dispatch(setloading(true))
		dispatch(setMore(true))
		dispatch(getPostData({...filters,sort_field:sortField,sort_value:sortvalue,searchData:searchValue,pageNo:0,hasMore:true,preData:[]}));
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [])


	const onChangeHandler = (e) => {
		setSeachdata(e.target.value)
	}

	useEffect(() => {
		setTimeout(() => {
			if(currentProduct!==""&&postData!==""){
				var ele = document.getElementById(currentProduct);  
				ele?.scrollIntoView({
					behavior: 'smooth'
				  });
			//   window.scrollTo(ele.offsetLeft,ele.offsetTop);
			  }
			 
		}, 1000);
	}, [postData])

	let oninterSection=(entries)=>{
		let firstEntry=entries[0];
		if(firstEntry.isIntersecting&&hasMore){
			dispatch(getPostData({...filters,sort_field:sortField,sort_value:sortvalue,searchData:searchValue,pageNo:pageNo,hasMore:hasMore,preData:postData}));
		}
	}

	let productNavigate=(item)=>{
		dispatch(setCurrentProduct(item._id));
		// navigate(`/${item.user_id.username}/${item.list_id}/${item.make?.make_name}-${item.model?.model_name}`)
		if(item.make){
			navigate(`/${item.user_id.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
		}else{
			navigate(`/${item.user_id.username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
		}
	}

	useEffect(()=>{
		const observer=new IntersectionObserver(oninterSection);
		if(observer&&elementRef.current){
			observer.observe(elementRef.current)
		}

		return ()=>{
			if(observer){
				observer.disconnect()
			}
		}
	},[postData])


	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
		  if (searchRef.current && !searchRef.current.contains(event.target)) {
			$("#search").val()!==""&&setFocus(false);
		  }
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
		  // Unbind the event listener on clean up
		  document.removeEventListener("mousedown", handleClickOutside);
		};
	  }, [searchRef]);
	// useEffect(() => {
	// 	const listViewButton = document.querySelector('.list-view-button');
	// 	const gridViewButton = document.querySelector('.grid-view-button');
	// 	const list = document.querySelector('.productSec');
	
	// 	$(".list-view-button").click(function(){
	// 	  list.classList.remove('grid-view-filter');
	// 	  list.classList.add('list-view-filter');
	// 	})
	
	// 	$(".grid-view-button").click(function(){
	// 	  list.classList.remove('list-view-filter');
	// 	  list.classList.add('grid-view-filter');
	// 	})
	//   }, [])
	return (
		<>

			<div class="searchSec">
				<div class="container">
					<div class="serchpnl">
						<div class="searchlft" ref={searchRef}>
						<form style={{height:"100%"}}  onSubmit={(e) => { e.preventDefault();setFocus(false); $("#search").blur();
							dispatch(setSearchdata(searchData));dispatch(setloading(true)); dispatch(getPostData({...filters,sort_field:sortField,sort_value:sortvalue, searchData: searchData,pageNo:0,hasMore:hasMore,preData:[] })); }}>
							<input id='search' onFocus={(e)=>{e.target.placeholder="";setFocus(true)}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.search}} type="text" value={searchData} onChange={onChangeHandler} placeholder={searchData===""&&lan.search} />
							{(searchData===""||focus)&&<input type="submit" value="" />}
						</form>
							{(searchData!==""&&!focus)&&<button className='cros_icon' onClick={(e) => { e.preventDefault();setFocus(true);setSeachdata("");dispatch(setSearchdata(""));dispatch(setloading(true)); dispatch(getPostData({...filters,sort_field:sortField,sort_value:sortvalue, searchData: "",pageNo:0,hasMore:hasMore,preData:[] })); }}><img src={close_01} alt='' /></button>}
						</div>
						

						<div class="searchfltr">
							<button data-bs-toggle="modal" data-bs-target="#sortmodal">{lan.sort}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="gridBtn">
				<div class="container">
					<div class="filter-buttons">
						<div onClick={()=>{dispatch(setListingMode("list"))}} class="list-view-button">{lan.list}<i><img style={{    width: "25px"}} src={listView} alt="" /></i></div>
						<div onClick={()=>{dispatch(setListingMode("grid"))}} class="grid-view-button"><i><img style={{    width: "25px"}} src={gridView} alt="" /></i> GRID</div>
					</div>
				</div>
			</div>

			<div class={listingMode==="list"?"productSec list-view-filter":"productSec grid-view-filter"}>
				<div class="container">
					<div class="row">
						{postData && postData.length > 0 ? postData.map(item => {
							return <div class="col-lg-3 productcolumn" id={item._id}>
								<div class="productbx">
									<div class="productbxtop">
										<div class="productbxtopimg">
											<a href="javascript:void(0)" class="userSmall"><img src={item.user_id.seller_type==="private_seller"?userSmall:smallDealer} alt="" /></a>
											{item.image&&JSON.parse(item.image).length>0 && <img onClick={(e) => { e.preventDefault();productNavigate(item) }} src={`${BASE_URL}/uploads/post/${JSON.parse(item.image)[0]}`} alt="" />}
											{item.selling_rent==="Rent"&&<h5 className='rentBtn'>RENT</h5>}
											{item.new_used==="Novo"&&<h5 className='rentBtn newBtn'>NOVO</h5>}
											<a href="" onClick={(e) => { e.preventDefault(); wishlistClick(e, item) }} class="grayHeart">{item.wishlistData?.is_favourite ? <img src={colorHeert} alt="" /> : <img src={grayHeart} alt="" />}</a>
										</div>
										<div class="productbxtoprtlst" onClick={() => { productNavigate(item)}}>
											<ul>
												<li><span><img src={yr}/></span> {item.year}</li>
												<li><span><img src={dsl}/></span> {item.fuel?item.fuel:"BENZIN"}</li>
												<li><span><img src={milg}/></span> {item.mileage}</li>
												<li className='trnmbl'><span><img src={auto}/></span> {item.transmission}</li>
												<li><span><img src={lcn}/></span><em className={item.location?.city_name.length>10?"smallCity":"bigCity"}>{item.location?.city_name}</em></li>
											</ul>
										</div>
									</div>
									<div class="productbxbtm" onClick={() => { productNavigate(item) }}>
										<h3>{item.name}</h3>
										<strong><span>KM</span> {item.price}</strong>
									</div>
								</div>
							</div>
						}):
						<div class="viditext" style={{marginTop:"50px",fontWeight:"500", width: "100%"}} >{lan.no_data}...</div>
						}
					</div>
				</div>
			</div>
			{hasMore&&<div  ref={elementRef} class="viditext" style={{marginTop:"50px",opacity:0}} >Load More Listings...</div>}
			<SortSec searchData={searchData} />
		</>

	)
}

export default ProductSec