import React, { useEffect, useRef, useState } from 'react'
import drp from "../../images/drp.png"
import Close from "../../images/close_01.svg"
import stear2 from "../../images/filter_model.svg"
import pricepic from "../../images/2_filter_price.svg"
import year_pic from "../../images/3_filter_year.svg"
import fuel_pic from "../../images/4_filter_fuel.svg"
import mileage_pic from "../../images/5_filter_mileage.svg"
import engine_pic from "../../images/6_filter_engine_size.svg"
import pencil2 from "../../images/Pencil.svg"
import axles_pic from "../../images/23_filter_number_of_axles.svg"
import power_pic from "../../images/7_filter_engine_power.svg"
import trans_pic from "../../images/8_filter_transmission.svg"
import drive_pic from "../../images/9_filter_drive.svg"
import color_pic from "../../images/11_filter_color.svg"
import weight_pic from "../../images/20_filter_total_weight.svg"
import carry_pic from "../../images/21_filter_carrying_capacity.svg"
import seats_pic from "../../images/12_filter_seats.svg"
import doors_pic from "../../images/13_filter_doors.svg"
import env_pic from "../../images/10_filter_enviroment.svg"
import srtfltr from "../../images/24_filter_other_details.svg"
import publish from "../../images/61_publish_listing.svg"
import back from "../../images/back.svg"
import car_pic from "../../images/54_car_images.svg"
import car_credits from "../../images/38_56_buy_credits_black.svg"
import crcoin from "../../images/37_credits_black.svg"
import Close_indow from "../../images/Close_indow.png"
import cooling from "../../images/22_filter_cooling.svg"
import CloseTwo from "../../images/close_01.svg"
import { BASE_URL, lan } from '../../config'
import { toast } from 'react-toastify'
import $, { event } from "jquery";
import axios from 'axios'
import { getPostData, getUserData, setCurrentProduct, setModal, setMode, setUserData, setloading } from '../../Redux/ActionCreater'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import MultiSelect from '../../partial/MultiSelect'
import Resizer from "react-image-file-resizer";
import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs'

function UpdateModal({ data, setData }) {
    let [autoUpdate, setAutoUpdate] = useState(false);
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [oldimages, setOldImages] = useState([]);
    const [images, setImages] = useState([]);
    const [dltimages, setDeltImages] = useState([]);
    const [credit, setCredit] = useState("");
    let [formData, setFormData] = useState({
        carType: "",
        subType: "",
        price: "",
        make: "",
        model: "",
        selling_rent: "",
        new_used: "",
        year: "",
        fuel: "",
        mileage: "",
        size: "",
        power: "",
        transmission: "",
        drive: "",
        env: "",
        color: "",
        seats: "",
        doors: "",
        axles: "",
        assign_credit: "",
        image: [],
        newimage: [],
        oldimages: [],
        name: "",
        description: "",
        other_details: [],
        capacity: "",
        weight: "",
        cool: "",
        auto_credit: false

    });
    const [otherDetails, setOtherDetails] = useState([]);
    let [years, setYears] = useState();
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [price, setPrice] = useState([]);
    const [color, setColor] = useState([]);
    const [year, setYear] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [mileage, setMileage] = useState([]);
    const [engineSize, setEngineSize] = useState([]);
    const [power, setPower] = useState([]);
    const [tranmission, setTransmission] = useState([]);
    const [drive, setDrive] = useState([]);
    const [seats, setSeats] = useState([]);
    const [doors, setDoors] = useState([]);
    const [enviroment, setEnviroment] = useState([]);
    const [weight, setWeight] = useState([]);
    const [carry, setCarry] = useState([]);
    const [cool, setCool] = useState([]);
    const [axles, setAxles] = useState([]);
    const [city, setCity] = useState([]);
    const [preData, setPreData] = useState([]);
    const multiselectRef = useRef(null);
    let newData = new FormData();
    // useEffect(() => {
    //     let startYear = 2010
    //     let currentYear = new Date().getFullYear();
    //     var years = []
    //     for (var i = startYear; i <= currentYear; i++) {
    //         years.push(i);
    //     }
    //     setYears(years)
    // }, [])
    const { userData } = useSelector(
        (state) => ({
            userData: state.dataReducer.userData,
        }),
        shallowEqual
    );
    const fetchData = async (id) => {
        try {
            await axios({
                url: BASE_URL + "/api/get-pre-value",
                method: "POST",
                data: {
                    id: id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    setPreData(res.data.data);
                    setOtherDetails(res.data.other_details)
                    setMake(res.data.makes_data);
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const fetchModels = async (id) => {
        try {
            await axios({
                url: BASE_URL + "/api/get-models",
                method: "POST",
                data: { make_id: id }
            }).then(res => {
                if (res.data.status === 200) {
                    // let arr=[]
                    // res.data.data.map(item=>{
                    //     arr.push(item.city_name)
                    // })
                    setModel(res.data.data);

                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (price.length == 0) {


            let preValue = {}
            for (let i = 0; i < preData.length; i++) {
                let key = preData[i].slug

                preValue = { ...preValue, [key]: preData[i].preValue }
            }
            // preData.length>0&&preData.map(item=>{
            //     preValue={...preValue,[item.slug]:item.preValue}
            // })
            // preValue.make && setMake([...preValue.make])
            // preValue.model && setModel([...preValue.model])
            preValue.price && setPrice([...preValue.price].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.color && setColor([...preValue.color])
            preValue.year && setYears([...preValue.year].sort(function (a, b) { return Number(b.value)-Number(a.value) }))
            preValue.fuel && setFuel([...preValue.fuel])
            preValue.mileage && setMileage([...preValue.mileage].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_size && setEngineSize([...preValue.engine_size].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_power && setPower([...preValue.engine_power].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_power && setTransmission([...preValue.transmission])
            preValue.drive && setDrive([...preValue.drive])
            preValue.seats && setSeats([...preValue.seats].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.doors && setDoors([...preValue.doors].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.enviroment && setEnviroment([...preValue.enviroment])
            preValue.total_weight && setWeight([...preValue.total_weight].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.carrying_capcity && setCarry([...preValue.carrying_capcity].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.cooling && setCool([...preValue.cooling])
            preValue.number_of_axles && setAxles([...preValue.number_of_axles].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
        }

    }, [preData])
    let clickMakeModal = (e) => {
        e.preventDefault();
        if (formData.subType === ""&&formData.make == "") {
            return
            toast.error("Please choose make!");
        }
        if (formData.subType === ""&&formData.model == "") {
            return 
            toast.error("Please choose model!");
        }
        if (formData.price == "") {
            return 
            toast.error("Please enter price!");
        }
        console.log(e.target)
        $("#upmakemodal").prop('disabled', false);
        $("#upmakemodal").click();
        $("#upmakemodal").prop('disabled', true);

    }

    let clickYearModal = (e) => {
        e.preventDefault();
        if (formData.year == "") {
            return toast.error("Please choose year!");
        }
        if (formData.subType === "" && formData.fuel == "") {
            return toast.error("Please choose fuel!");
        }
        if (formData.mileage == "") {
            return toast.error("Please enter mileage!");
        }
        console.log(e.target)
        $("#upyearmodal").prop('disabled', false);
        $("#upyearmodal").click();
        $("#upyearmodal").prop('disabled', true);

    }

    let clickEngineModal = (e) => {
        e.preventDefault();

        if (formData.size == "") {
            return
            toast.error("Please choose engine size!");
        }
        if (formData.power == "") {
            return
            toast.error("Please enter engine power!");
        }
        if (formData.subType !== "" && formData.transmission == "") {
            return
            toast.error("Please enter engine power!");
        }
        console.log(e.target)
        $("#upenginemodal").prop('disabled', false);
        $("#upenginemodal").click();
        $("#upenginemodal").prop('disabled', true);
    }

    let clickEnvModal = (e) => {
        e.preventDefault();
        if (formData.transmission == "") {
            return toast.error("Please choose transmission!");
        }
        if (formData.subType === "" && formData.drive == "") {
            return toast.error("Please choose drive!");
        } if (formData.env == "") {
            return toast.error("Please choose enviroment!");
        }
        $("#upenvmodal").prop('disabled', false);
        $("#upenvmodal").click();
        $("#upenvmodal").prop('disabled', true);
    }

    let clickColorModal = (e) => {
        e.preventDefault();
        if (formData.color == "") {
            return toast.error("Please choose color!");
        }
        if (formData.seats == "") {
            return toast.error("Please choose seats!");
        } if (formData.subType === "" && formData.doors == "") {
            return toast.error("Please choose doors!");
        }
        $("#upcolormodal").prop('disabled', false);
        $("#upcolormodal").click();
        $("#upcolormodal").prop('disabled', true);
    }

    let clickImageModal = (e) => {
        e.preventDefault();
        if (formData.image == "") {
            return toast.error("Please choose image!");
        }
        $("#upimagemodal").prop('disabled', false);
        $("#upimagemodal").click();
        $("#upimagemodal").prop('disabled', true);
    }

    let clickNameModal = (e) => {
        e.preventDefault();
        if (formData.name == "") {
            return toast.error("Please enter name!");
        }
        if (formData.description == "") {
            return toast.error("Please enter detail description!");
        }
        $("#upnamemodal").prop('disabled', false);
        $("#upnamemodal").click();
        $("#upnamemodal").prop('disabled', true);
    }


    const handleMultipleImages = async (evnt) => {

        try {
            let arr = [];
            let number = 0;
            let imgArr = [...evnt.target.files]
            evnt.preventDefault();
            dispatch(setloading(true));
            newData.append("old_image", JSON.stringify(oldimages))
            newData.append("id", data._id)
            newData.append("delete_image", JSON.stringify(dltimages))
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1600,
                useWebWorker: true,
                fileType: 'image/webp'
            }

            //  imgArr.map((item)=>{
            //          Resizer.imageFileResizer(
            //             item,
            //             1600,
            //             1600,
            //             "webp",
            //             20,
            //             0,
            //             (uri) => {
            //               console.log(uri);
            //               newData.append("image", uri);
            //               number++;
            //               if(number===imgArr.length){
            //                 update();
            //               }
            //             },
            //             "file",
            //             200,
            //             200
            //           );
            //     })

            imgArr.map(async (file) => {
                // const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                // console.log(compressedFile);
                // selectedFIles.push(URL.createObjectURL(compressedFile));
                // setImg([...selectedFIles])

                // newData.append("image", new File([compressedFile], compressedFile.name + ".webp"));
                // number++;
                // if (number === imgArr.length) {
                //     update();
                // }

                // selectedFIles.push(URL.createObjectURL(compressedFile))
                // setImages(selectedFIles);
                // fileArr.push(compressedFile)
                // setFormData({ ...formData, image: fileArr })



                new Compressor(file, {
                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    minWidth:1400,
                    maxWidth:1600,
                    maxHeight:1600,
                    convertTypes:'image/webp',
                    convertSize:1500000,
                    success: (compressedResult) => {
                      // compressedResult has the compressed file.
                      // Use the compressed file to upload the images to your server. 
                      
                      newData.append("image", new File([compressedResult], compressedResult.name + ".webp"));
                      number++;
                      if (number === imgArr.length) {
                          update();
                      }    
                      console.log(compressedResult,new File([compressedResult], compressedResult.name+".webp",{type:'image/webp'}));
                    },
                  });
            })


            let update = async () => {
                await axios({
                    url: BASE_URL + "/api/listings-image-update",
                    method: "POST",
                    headers: {
                        authorization: token
                    },
                    data: newData,
                }).then(res => {

                    if (res.data.status === 200) {
                        setOldImages([...JSON.parse(res.data.data.image)])

                    } else {
                        toast.error(res.data.message)
                    }
                    dispatch(setloading(false))
                })
                    .catch(err => {
                        console.log(err);
                        if (err?.response?.status === 401) {
                            logOut()
                        }
                    })
            }





            // for(let i=0;i<evnt.target.files.length;i++){
            //     //   try {
            //          Resizer.imageFileResizer(
            //           evnt.target.files[i],
            //           1600,
            //           1600,
            //           "webp",
            //           20,
            //           0,
            //           (uri) => {
            //             console.log(uri);
            //             newData.append("image", uri);
            //             console.log(newData);
            //             // selectedFIles.push(URL.createObjectURL(uri))
            //             // setImages(selectedFIles);
            //             // fileArr.push(uri)
            //             // setFormData({ ...formData, image: fileArr })
            //           },
            //           "file",
            //           200,
            //           200
            //         );
            //     //   } catch (err) {
            //     //     console.log(err);
            //     //   }
            //   }

            // if (evnt.target.files.length > 0) {
            //     for (let i = 0; i < evnt.target.files.length; i++) {
            //         newData.append("image", evnt.target.files[i])
            //     }
            // }
            console.log("object", newData.image);

        } catch (error) {
            console.log(error)
            if (error?.response?.status === 401) {
                logOut()
            }
        }

        // setFormData({ ...formData, newimage: evnt.target.files })
        // const selectedFIles = [];
        // const targetFiles = evnt.target.files;
        // const targetFilesObject = [...targetFiles]
        // targetFilesObject.map((file) => {

        //     return selectedFIles.push(URL.createObjectURL(file))
        // })
        // setImages(selectedFIles);
    }

    const logOut = () => {
        // setUserData("");
        dispatch(setMode(""));
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        dispatch(setCurrentProduct(""));
        toast.error(lan.login_continue_msg);
        dispatch(getPostData({ preData: [] }));
        navigate("/")
    }

    let otherDetailsClick = (item) => {
        let index = -1

        formData.other_details.map(
            (value, idx) => {
                if (item.slug == value.slug) {
                    index = idx
                }
            });
        console.log(index)
        if (index > -1) {
            let arr = [...formData.other_details]
            arr.splice(index, 1)
            setFormData({ ...formData, other_details: arr })
        } else {
            let arr = [...formData.other_details]
            arr.push(item)
            setFormData({ ...formData, other_details: arr })
        }
    }

    let clickDetailsModal = (e) => {
        e.preventDefault();
        $("#detailsmodal").prop('disabled', false);
        $("#detailsmodal").click();
        $("#detailsmodal").prop('disabled', true);
    }

    let clickCarryModal = (e) => {
        e.preventDefault();
        if (formData.capacity == "") {
            return
            toast.error("Please choose color!");
        }
        if (formData.weight == "") {
            return
            toast.error("Please choose seats!");
        }
        if (formData.carType === "truck" && formData.axles == "") {
            return
            toast.error("Please choose seats!");
        }
        $("#upcarrymodal").prop('disabled', false);
        $("#upcarrymodal").click();
        $("#upcarrymodal").prop('disabled', true);
    }


    let submitDetails = async (e, id, mode) => {
        try {

            e.preventDefault();
            console.log(formData.other_details)
            if (mode && formData.other_details.length === 0) {
                return toast.error("Please choose atleast one option!");
            }
            dispatch(setloading(true))
            let arr = []
            for (let i = 0; i < formData.other_details.length; i++) {
                arr.push(formData.other_details[i].slug)
            }
            let updata = {
                id: data._id,
                carrying_capcity: formData.capacity ? formData.capacity : "",
                color: formData.color,
                cooling: formData.cooling ? formData.cooling : "",
                description: formData.description,
                doors: formData.doors,
                drive: formData.drive,
                engine_power: formData.power,
                engine_size: formData.size,
                enviroment: formData.env,
                fuel: formData.fuel,
                make: formData.make,
                mileage: formData.mileage,
                model: formData.model,
                name: formData.name,
                new_used: formData.new_used,
                number_of_axles: formData.axles ? formData.axles : "",
                price: formData.price,
                seats: formData.seats,
                selling_rent: formData.selling_rent,
                total_weight: formData.weight ? formData.weight : "",
                transmission: formData.transmission,
                year: formData.year,
                other_details: arr,
                cooling: formData.cool

            }
            await axios({
                url: BASE_URL + "/api/listings-details-update",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: updata,
            }).then(res => {
                if (res.data.status === 401) {
                    logOut()
                }
                if (res.data.status === 200) {
                    setData(res.data.data)
                    // let data=res.data.data
                    // setFormData({...formData,
                    //     carType: data.vehicle_type_id.slug,
                    //     subType: data.vehicle_sub_type_id?data.vehicle_sub_type_id.slug:"",
                    //     price: data.price?data.price:"",
                    //     make: data.make?data.make:"",
                    //     model: data.model?data.model:"",
                    //     selling_rent:data.selling_rent?data.selling_rent:"",
                    //     new_used:data.new_used?data.new_used:"",
                    //     year: data.year?data.year:"",
                    //     fuel: data.fuel?data.fuel:"",
                    //     mileage: data.mileage?data.mileage:"",
                    //     size: data.engine_size?data.engine_size:"",
                    //     power: data.engine_power?data.engine_power:"",
                    //     transmission: data.transmission?data.transmission:"",
                    //     drive: data.drive?data.drive:"",
                    //     env: data.enviroment?data.enviroment:"",
                    //     color: data.color?data.color:"",
                    //     seats: data.seats?data.seats:"",
                    //     doors: data.doors?data.doors:"",
                    //     axles: data.number_of_axles?data.number_of_axles:"",
                    //     assign_credit: data.assign_credit?data.assign_credit:"",
                    //     image: data.image?data.image:[],
                    //     name: data.name?data.name:"",
                    //     description: data.desciption?data.desciption:""
                    // })upsubmitmodal357
                    $("#" + id).prop('disabled', false);
                    $("#" + id).click();
                    $("#" + id).prop('disabled', true);
                    $("#upsubmitmodal357").prop('disabled', false);
                    $("#upsubmitmodal357").click();
                    $("#upsubmitmodal357").prop('disabled', true);
                    dispatch(setloading(false))
                    dispatch(setModal(false))
                    toast.success("Uspješno izvršene izmjene!");
                } else {
                    dispatch(setloading(false))
                    dispatch(setModal(false))
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status)
            if (error.response.status === 401) {
                dispatch(setloading(false))
                logOut()
            }
        }
    }

    let submitImage = async (e) => {
        try {
            e.preventDefault();

            if (oldimages.length === 0 && formData.newimage.length === 0) {
                return toast.error("Please choose image!");
            }
            // let arr = [...formData.newimage];
            newData.append("old_image", JSON.stringify(oldimages))
            newData.append("id", data._id)
            newData.append("delete_image", JSON.stringify(dltimages))


            if (formData.newimage.length > 0) {
                for (let i = 0; i < formData.newimage.length; i++) {
                    newData.append("image", formData.newimage[i])
                }
            }

            await axios({
                url: BASE_URL + "/api/listings-image-update",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: newData,
            }).then(res => {

                if (res.data.status === 200) {
                    setImages([])
                    setDeltImages([])
                    // setData(res.data.data);
                    setData({ ...data, image: res.data.data.image });
                    setFormData({ ...formData, newimage: [] })
                    $("#upimagemodal").prop('disabled', false);
                    $("#upimagemodal").click();
                    $("#upimagemodal").prop('disabled', true);
                    toast.success("Uspješno izvršene izmjene!");
                } else {
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status)
            if (error.response.status === 401) {
                logOut()
            }
        }
    }

    let deleteImg = (url) => {
        console.log(url, oldimages)
        let dltimg = dltimages;
        dltimg.push(url)
        setDeltImages(dltimg);
        let arr = oldimages;
        let idx = arr.indexOf(url)
        if (idx > -1) {
            arr.splice(idx, 1);
            setOldImages([...arr])
        }
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(oldimages);
        // let arrImg=[...formData.image];

        const [reorderedItem] = items.splice(result.source.index, 1);
        // const [reorderedImg] = arrImg.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        // arrImg.splice(result.destination.index, 0, reorderedImg);

        setOldImages(items);
        // setFormData({ ...formData, image: [...arrImg] })
    }


    useEffect(() => {
        setOldImages([...JSON.parse(data.image)])
        setFormData({
            ...formData,
            carType: data.vehicle_type_id.slug,
            subType: data.vehicle_sub_type_id ? data.vehicle_sub_type_id.slug : "",
            price: data.price ? data.price : "",
            make: data.make ? data.make._id : "",
            model: data.model ? data.model._id : "",
            selling_rent: data.selling_rent ? data.selling_rent : "",
            new_used: data.new_used ? data.new_used : "",
            year: data.year ? data.year : "",
            fuel: data.fuel ? data.fuel : "",
            mileage: data.mileage ? data.mileage : "",
            size: data.engine_size ? data.engine_size : "",
            power: data.engine_power ? data.engine_power : "",
            transmission: data.transmission ? data.transmission : "",
            drive: data.drive ? data.drive : "",
            env: data.enviroment ? data.enviroment : "",
            color: data.color ? data.color : "",
            seats: data.seats ? data.seats : "",
            doors: data.doors ? data.doors : "",
            axles: data.number_of_axles ? data.number_of_axles : "",
            assign_credit: data.assign_credit ? data.assign_credit : "",
            image: data.image ? data.image : [],
            name: data.name ? data.name : "",
            other_details: data.other_details ? data.other_details : [],
            description: data.description ? data.description : "",
            capacity: data.carrying_capcity ? data.carrying_capcity : "",
            weight: data.total_weight ? data.total_weight : "",
            cool: data.cooling ? data.cooling : "",
            auto_credit: data.auto_credit ? data.auto_credit : false
        })
        fetchModels(data?.make?._id);
    }, [data])


    let assignCreditHandle = async (e, type) => {
        try {
            e.preventDefault();
            // let value = $("#upcredit").val();
            if (credit === "") {
                return toast.error("Unesite iznos kredita!")
            }
            if (type === "remove" && credit >= data.assign_credit) {
                return toast.error("Oglas ne može ostati bez kredita!")
            }

            await axios({
                url: BASE_URL + "/api/assign-credit",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    type: type,
                    assign_credit: credit,
                    auto_credit: formData.auto_credit,
                    id: data._id
                },
            }).then(res => {
                if (res.data.status === 401) {
                    logOut()
                }
                if (res.data.status === 200) {
                    setData(res.data.data);
                    localStorage.setItem('login_data', JSON.stringify({ ...userData, total_credit: res.data.userData.total_credit, total_assign_credit: res.data.userData.total_assign_credit }));
                    dispatch(setUserData({ ...userData, total_credit: res.data.userData.total_credit, total_assign_credit: res.data.userData.total_assign_credit }))
                    setCredit("")
                    if (type === "add") {
                        toast.success(lan.credit_add_succss)
                    } else {
                        toast.success(lan.credit_remove_succss)
                    }
                } else {
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status)
            if (error.response.status === 401) {
                logOut()
            }
        }
    }

    let updateAutoCreditHandle = async (e) => {
        try {
            e.preventDefault();
            await axios({
                url: BASE_URL + "/api/update-auto-credit",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    auto_credit: !formData.auto_credit,
                    id: data._id
                },
            }).then(res => {
                if (res.data.status === 401) {
                    logOut()
                }
                if (res.data.status === 200) {
                    setData(res.data.data);
                    setFormData({ ...formData, auto_credit: !formData.auto_credit });
                } else {
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status)
            if (error.response.status === 401) {
                logOut()
            }
        }
    }



    useEffect(() => {
        fetchData(data?.vehicle_type_id?._id)
    }, [])
    return (
        <>
            <div class="modal  dashbordModal ovrfHide fade" id="upmodal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(setModal(false)) }}>
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "1/7" : "1/6" : "1/3"}</span>
                                </div>
                                <a style={{ opacity: formData.subType === "" ? (formData.make !== "" && formData.model !== "" && formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? 1 : 0.33) : (formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? 1 : 0.33) }}
                                    disabled={formData.subType === "" ? (formData.make !== "" && formData.model !== "" && formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? false : true) : (formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? false : true)} href='' onClick={(e) => { clickMakeModal(e) }}
                                >
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upmakemodal" disabled data-bs-toggle="modal" data-bs-target="#upmodal3"   ></button>

                        <div class="modal-body">
                            <div class={formData.subType === ""?"selrtypoup":"selrtypoup motoTypeByke"} style={{ "display": "block" }}>
                                <div class="seller-type-pop">

                                    <div class="row">
                                        {formData.subType === ""&&<div class="col-lg-6 col-md-6 col-12">
                                            <div class="twordobx">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={stear2} alt />
                                                    </div>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        {/* <MultiSelect
                                                            displayValue="make_name"
                                                            // ref={multiselectRef}
                                                            showArrow={true}
                                                            avoidHighlightFirstOption={true}
                                                            style={{
                                                                chips: {
                                                                    background: "transparent",
                                                                    fontSize: "22px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    color: "#000"
                                                                },
                                                                searchBox: {
                                                                    justifyContent: "center",
                                                                    color: "#989898"
                                                                },
                                                                inputField: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                },
                                                                option: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                }
                                                            }
                                                            }
                                                            singleSelect={true}
                                                            // customArrow={<img src={drp} />}
                                                            // onSearch={function noRefCheck(){console.log("sear")}}
                                                            onSelect={function noRefCheck(selectedList, selectedItem) {
                                                                fetchModels(selectedItem._id);setFormData({ ...formData, make: selectedItem._id, model: "" });setModel([]);multiselectRef.current.resetSelectedValues()
                                                            }}
                                                            selectedValues={[formData.make]}
                                                            placeholder={lan.choose_make}
                                                            options={make}
                                                        /> */}
                                                        <select value={formData.make} onChange={(e) => { setFormData({ ...formData, make: e.target.value, model: "" }); fetchModels(e.target.value) }} class="chosemk">
                                                            <option hidden disabled value="">{lan.choose_make}</option>
                                                            {make && make.map(i => {
                                                                return <option value={i._id}>{i.make_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select value={formData.model} onChange={(e) => { setFormData({ ...formData, model: e.target.value }) }} class="chosemk">
                                                            <option hidden disabled value="">{lan.choose_model}</option>
                                                            {model && model.map(i => {
                                                                return <option value={i._id}>{i.model_name}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="twordobx">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={pricepic} />
                                                    </div>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.price} (KM)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, price: e.target.value }) } }} value={formData.price}
                                                            className='iputprice' type='tel' placeholder={`${lan.price} (KM)`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                                <div className={formData.subType === ""?'chkbox-optin':'chkbox-optin margTop'}>
                                    <div className='twochkline'>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, selling_rent: "Selling" }) }}>
                                            <input type="radio" id="test1" checked={formData.selling_rent == "Selling" ? true : false} name="checkbox-group" class="ship_address" value="1" />
                                            <label for="test1">{lan.selling}</label>
                                        </div>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, selling_rent: "Rent" }) }}>
                                            <input type="radio" id="test2" checked={formData.selling_rent == "Selling" ? false : true} name="checkbox-group" class="ship_address" value="1" />
                                            <label for="test2">{lan.rent}</label>
                                        </div>
                                    </div>
                                    <div className='twochkline'>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, new_used: "Korišteno" }) }}>
                                            <input type="radio" id="test3" checked={formData.new_used == "Korišteno" ? true : false} name="checkbox-group1" class="ship_address1" value="1" />
                                            <label for="test3">{lan.used}</label>
                                        </div>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, new_used: "Novo" }) }}>
                                            <input type="radio" id="test4" checked={formData.new_used == "Korišteno" ? false : true} name="checkbox-group1" class="ship_address1" value="1" />
                                            <label for="test4">{lan.new}</label>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal upmodal dashbordModal ovrfHide fade" id="upmodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#upmodal2">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "2/7" : "2/6" : "2/3"}</span>
                                </div>

                                <a style={{ opacity: formData.year !== "" && formData.mileage !== "" ? formData.subType === "" ? formData.fuel === "" ? 0.33 : 1 : formData.cool === "" ? 0.33 : 1 : 0.33 }} disabled={formData.year !== "" && formData.mileage !== "" ? formData.subType === "" ? formData.fuel === "" ? true : false : formData.cool === "" ? true : false : true} href='' onClick={(e) => { clickYearModal(e) }}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upyearmodal" disabled data-bs-toggle="modal" data-bs-target="#upmodal4"   ></button>
                        <div class="modal-body">
                            <div class="selrtypoup">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={year_pic} alt />
                                                    </div>
                                                    <p>{lan.year}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        {/* <MultiSelect 
                                                        displayValue={"value"}
                                                        setFormData={setFormData}
                                                        formData={formData}
                                                        options={years}
                                                        data={"year"}
                                                        preSelect={formData.year}
                                                        /> */}
                                                        <select onChange={(e) => { setFormData({ ...formData, year: e.target.value }) }} value={formData.year} class="chosemk">
                                                            <option value="" >{lan.choose}</option>
                                                            {years && years.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={fuel_pic} alt />
                                                    </div>
                                                    <p>{lan.fuel}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, fuel: e.target.value }) }} value={formData.fuel} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {fuel && fuel.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {formData.subType !== "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={cooling} alt />
                                                    </div>
                                                    <p>{lan.COOLING}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select style={{ color: formData.cool !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, cool: e.target.value }) }} value={formData.cool} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {cool && cool.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={mileage_pic} alt />
                                                    </div>
                                                    <p>{lan.mileage}</p>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter} (km)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, mileage: e.target.value }) } }} value={formData.mileage}
                                                            class="chosemk2" type='tel' placeholder={`${lan.enter} (km)`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="upmodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#upmodal3">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "3/7" : "3/6" : "3/3"}</span>
                                </div>

                                {formData.subType === "" && <a style={{ opacity: formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? 0.33 : 1 : 0.33 }} disabled={formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? true : false : true} href='javascript:void(0);' onClick={clickEngineModal}>
                                    <img src={back} />
                                </a>}
                                {formData.subType !== "" &&
                                    <a style={{ transform: "rotate(0deg)", opacity: formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? 0.33 : 1 : 0.33 }} disabled={formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? true : false : true} href='' onClick={(e) => submitDetails(e, "upsubmitmodal963", false)} >
                                        <img src={publish} />
                                    </a>
                                }
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upsubmitmodal963" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upenginemodal" disabled data-bs-toggle="modal" data-bs-target={formData.carType === "truck" || formData.carType === "van" ? "#upmodal99" : "#upmodal5"}   ></button>
                        <div class="modal-body">
                            <div class="selrtypoup">
                                <div class="seller-type-pop fulebx">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-5 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={engine_pic} alt />
                                                    </div>
                                                    <p>{lan.size}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, size: e.target.value }) }} value={formData.size} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {engineSize && engineSize.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType !== "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={trans_pic} alt />
                                                    </div>
                                                    <p>{lan.transmission}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select style={{ color: formData.transmission !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, transmission: e.target.value }) }} value={formData.transmission} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {tranmission && tranmission.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={power_pic} alt />
                                                    </div>
                                                    <p>{lan.power}</p>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter} (kW)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, power: e.target.value }) } }} value={formData.power}
                                                            class="chosemk2" type='tel' placeholder={`${lan.enter} (kW)`} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal dashbordModal ovrfHide fade" id="upmodal99" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content TopPading0">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#upmodal4">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>4/7</span>
                                </div>
                                <a style={{ opacity: (formData.capacity !== "" && formData.weight !== "") ? (formData.carType === "truck" && formData.axles !== "") ? 1 : 0.33 : 0.33 }} disabled={(formData.capacity !== "" && formData.weight !== "") ? (formData.carType === "truck" && formData.axles !== "") ? false : true : true} href='javascript:void(0);' onClick={clickCarryModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upcarrymodal" disabled data-bs-toggle="modal" data-bs-target="#upmodal5"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop">
                                <div class="seller-type-pop fulebx">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={carry_pic} alt />
                                                    </div>
                                                    <p>{lan.capacity}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select style={{ color: formData.capacity !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, capacity: e.target.value }) }} value={formData.capacity} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {carry && carry.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {(formData.carType === "truck" || formData.carType === "van") &&
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="twordobx yrfuel">
                                                    <div class="seletfrm">
                                                        <div class="stear-icon">
                                                            <img src={weight_pic} alt />
                                                        </div>
                                                        <p>{lan.weight}</p>
                                                        <div class="chosefrm">
                                                            <i><img src={drp} /></i>
                                                            <select style={{ color: formData.weight !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, weight: e.target.value }) }} value={formData.weight} class="chosemk">
                                                                <option value="">{lan.choose}</option>
                                                                {weight && weight.map(i => {
                                                                    return <option value={i.value}>{i.value}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {(formData.carType === "truck") &&
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="twordobx yrfuel">
                                                    <div class="seletfrm">
                                                        <div class="stear-icon">
                                                            <img src={axles_pic} alt />
                                                        </div>
                                                        <p>{lan.axles}</p>
                                                        <div class="chosefrm">
                                                            <i><img src={drp} /></i>
                                                            <select style={{ color: formData.axles !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, axles: e.target.value }) }} value={formData.axles} class="chosemk">
                                                                <option value="">{lan.choose}</option>
                                                                {axles && axles.map(i => {
                                                                    return <option value={i.value}>{i.value}</option>
                                                                })}
                                                            </select>
                                                            {/* <input
                                                        onFocus={(e) => { e.target.placeholder = "" }}
                                                        onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter}` }}
                                                        onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, weight: e.target.value }) } }} value={formData.weight}
                                                        class="chosemk2 iputprice" type='tel' placeholder={`${lan.enter}`} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal upmodal dashbordModal ovrfHide fade" id="upmodal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target={formData.carType === "truck" || formData.carType === "van" ? "#upmodal99" : "#upmodal4"}>
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "5/7" : "4/6"}</span>
                                </div>
                                {console.log(formData)}
                                <a style={{ opacity: (formData.transmission !== "" && formData.env !== "") ? (formData.subType === "" && formData.drive === "") ? 0.33 : 1 : 0.33 }} disabled={(formData.transmission !== "" && formData.env !== "") ? (formData.subType === "" && formData.drive === "") ? true : false : true} href='javascript:void(0);' onClick={clickEnvModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upenvmodal" disabled data-bs-toggle="modal" data-bs-target="#upmodal6"   ></button>
                        <div class="modal-body">
                            <div class="selrtypoup">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={trans_pic} alt />
                                                    </div>
                                                    <p>{lan.transmission}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, transmission: e.target.value }) }} value={formData.transmission} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {tranmission && tranmission.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={drive_pic} alt />
                                                    </div>
                                                    <p>{lan.drive}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, drive: e.target.value }) }} value={formData.drive} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {drive && drive.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={env_pic} alt />
                                                    </div>
                                                    <p>{lan.env}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, env: e.target.value }) }} value={formData.env} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {enviroment && enviroment.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal upmodal dashbordModal ovrfHide fade" id="upmodal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#upmodal5">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "6/7" : "5/6"}</span>
                                </div>

                                <a style={{ opacity: (formData.color !== "" && formData.seats !== "") ? (formData.subType === "" && formData.doors === "") ? 0.33 : 1 : 0.33 }} disabled={formData.color !== "" && formData.doors !== "" && formData.seats !== "" ? false : true} href='' onClick={clickColorModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upcolormodal" disabled data-bs-toggle="modal" data-bs-target="#upmodal7"   ></button>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upsubmitmodal357" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-body">
                            <div class="selrtypoup">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={color_pic} alt />
                                                    </div>
                                                    <p>{lan.color}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, color: e.target.value }) }} value={formData.color} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {color && color.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={seats_pic} alt />
                                                    </div>
                                                    <p>{lan.seats}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, seats: e.target.value }) }} value={formData.seats} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {seats && seats.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={doors_pic} alt />
                                                    </div>
                                                    <p>{lan.doors}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <select onChange={(e) => { setFormData({ ...formData, doors: e.target.value }) }} value={formData.doors} class="chosemk">
                                                            <option value="">{lan.choose}</option>
                                                            {doors && doors.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}


                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="upmodal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#upmodal6">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "7/7" : "6/6"}</span>
                                </div>

                                <a style={{ opacity: formData.other_details.length !== 0 ? 1 : 0.33, transform: "rotate(0deg)" }} href='' onClick={(e) => submitDetails(e, "upsubmitmodal356", true)} >
                                    <img src={publish} />
                                </a>

                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="upsubmitmodal356" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-body">
                            <div className="otherdetl-popup allrdiopop">
                                <div className="text-center">
                                    <figure>
                                        <img src={srtfltr} alt />
                                    </figure>
                                    <h4>{lan.details}</h4>
                                </div>
                                <div className="allopfiltr">
                                    <div className="row">
                                        {otherDetails && otherDetails.length > 0 && otherDetails.map((item, idx) => {
                                            return <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="customRadio ">
                                                    <input type="checkbox" checked={formData.other_details.some(
                                                        value => { return value.slug == item.slug })} onClick={() => otherDetailsClick(item)} name={"power" + idx} id={"option-0" + idx} />
                                                    <label htmlFor={"option-0" + idx} className={`option${idx} option-0${idx}`}>{item.name}</label>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal fade" id="upmodal20" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-dismiss="modal" aria-label="Close">
                                    <img src={back} />
                                </a>
                                <button style={{ opacity: 0 }} id="upnamemodal123" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                <a style={{ transform: "rotate(0deg)" }} href='' onClick={(e) => submitDetails(e, "upnamemodal123", false)} >
                                    <img src={publish} />
                                </a>
                            </div>
                        </div>
                        <div class="modal-body" style={{ alignItems: "flex-start" }}>
                            <div class="selrtypoup">
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={stear2} alt />
                                        </figure>
                                        <h4>DETALJI OGLASA</h4>
                                    </div>
                                    <div className='frmdtabx'>
                                        <div class="frmData">
                                            <textarea onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "NASLOV OGLASA" }}
                                                onChange={(e) => { if (e.target.value.length <= 50) { setFormData({ ...formData, name: e.target.value }) } }} value={formData.name} type="text" placeholder="NASLOV OGLASA" className='iputprice lstNameIn'></textarea>
                                            {/* <input onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "NAME OF THE NASLOV" }}
                                                onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }} value={formData.name} type="text" placeholder="NAME OF THE NASLOV" /> */}
                                        </div>
                                        <div class="frmData">
                                            <textarea onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "DETALJAN OPIS" }}
                                                onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} value={formData.description} placeholder='DETALJAN OPIS'></textarea>
                                        </div>



                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal upmodal dashbordModal fade TopPading0" style={{ overflow: "hidden" }} id="upmodal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-dismiss="modal" aria-label="Close">
                                    <img src={back} />
                                </a>
                                <button style={{ opacity: 0 }} id="upimagemodal" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                <a style={{ transform: "rotate(0deg)" }} href='' onClick={(e) => submitImage(e)} >
                                    <img src={publish} />
                                </a>
                                {/* <a style={{transform: "rotate(0deg)"}} href='' onClick={submitHandle} >
                                    <img src={publish} />
                                </a> */}
                            </div>
                        </div>
                        <div class="modal-body" style={{ overflow: "auto" }}>
                            <div class="selrtypoup" style={{ display: "block", overflowX: "hidden" }}>
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_pic} alt />
                                        </figure>
                                        <h4>SLIKE VOZILA</h4>
                                    </div>

                                    <div className='uplodbtn'>
                                        <form encType="multipart/form-data">
                                            <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                        </form>
                                        <span className='upldlnk'>UČITAJ</span>
                                    </div>
                                </div>

                                <div className="row imageUpdatePic" >
                                    {/* {
                                        oldimages.length > 0 && oldimages.map((url) => {
                                            return url && (
                                                <div className="col-lg-6 col-6 ">

                                                    <div className="cardpic oldPic">
                                                        <span class="deletePic" onClick={() => deleteImg(url)}><img src={Close} /></span>
                                                        <img src={`${BASE_URL}/uploads/post/${url}`} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    } */}

                                    <div>
                                        <div className="cardpic oldPic widthadj ">
                                            <div class="col-lg-6 editimage">
                                                <img src={`${BASE_URL}/uploads/post/${oldimages[0]}`} />
                                                {/* <form encType="multipart/form-data">
                                       <span class="deletePic editimage"><img src={pencil2} /></span>
                                           <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                       </form> */}
                                            </div>
                                        </div>
                                        {oldimages && oldimages.length > 0 && <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="oldimages">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="row" style={{ margin: "auto", maxWidth: "500px" }}>
                                                        {
                                                            oldimages.map((url, idx) => {
                                                                return url && (
                                                                    <Draggable
                                                                        // disableInteractiveElementBlocking={idx === 0 ? true : false} isDragDisabled={idx === 0 ? true : false} 
                                                                        key={url} draggableId={url} index={idx}>
                                                                        {(provided) => (
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="col-lg-6 col-6">
                                                                                <div className="cardpic oldPic">
                                                                                    <span class="deletePic" onClick={() => deleteImg(url)}><img src={Close} /></span>
                                                                                    <img src={`${BASE_URL}/uploads/post/${url}`} />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>}
                                    </div>


                                </div>
                                {/* <div className="row imageUpdatePic" >
                                    {
                                        images.map((url) => {
                                            return url && (
                                                <div className="col-lg-6 col-6">
                                                    <div className="cardpic">
                                                        <img src={url} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div> */}




                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal dashbordModal ovrfHide fade" id="upcredit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt="" />
                                <img src={CloseTwo} alt="" />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup scrlpop">
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_credits} alt />
                                        </figure>
                                        {/* <h4>AKTIVIRAJ OGLAS</h4> */}
                                        {/* <h4>{lan.add_credit}</h4> */}
                                    </div>
                                    <div className='frmdtabx'>
                                        <div class="frmData">
                                            {/* <i><img src="assets/images/Smartphone.png" alt="" /></i> */}
                                            <input id="credit"
                                                onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.credits_amount}` }}
                                                value={credit}
                                                onChange={(e) => { if (/^[+]?\d+$/.test(e.target.value) || e.target.value === "") { setCredit(e.target.value) } }}
                                                type="tel" placeholder={credit === "" ? lan.credits_amount : null} className='iputprice' />

                                        </div>
                                        <div style={{ display: "flex", justifyContent: data.approve_status === true ? "space-between" : "center" }}>
                                            <a href="#url" onClick={(e) => assignCreditHandle(e, "add")} class="assignbtn" style={{ width: "48%" }}>{lan.assign}</a>
                                            {data.approve_status === true && <a href="#url" onClick={(e) => assignCreditHandle(e, "remove")} class="assignbtn" style={{
                                                width: "48%", background: "#202629",
                                                color: "grey", pointerEvents: data.assign_credit > 1 ? "all" : "none", opacity: data.assign_credit > 1 ? 1 : 0.33
                                            }}>ODUZMI</a>}
                                        </div>

                                        <h6 className='upascred'>
                                            DODIJELJENO KREDITA: {data.assign_credit ? data.assign_credit : 0}</h6>
                                        <div className='automatic'>
                                            <div class="customCheckbox">
                                                <input type="checkbox" checked={formData.auto_credit} name='test01258' id='test01258' />
                                                <label htmlFor="test01258" className='test01258' onClick={(e) => { updateAutoCreditHandle(e) }}>{lan.automatic}</label>

                                                {/* {formData.auto_credit&&<span>1 {lan.month} = 1 {lan.credit}</span>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='popbtomtxt'>
                                <p><img src={crcoin} /> STANJE KREDITA: {userData.total_credit}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateModal