import React, { useEffect, useRef, useState } from 'react'
import drp from "../images/drp.png"
import Close_indow from "../images/Close_indow.png"
import Close from "../images/close_01.svg"
import stear2 from "../images/filter_model.svg"
import pricepic from "../images/2_filter_price.svg"
import year_pic from "../images/3_filter_year.svg"
import fuel_pic from "../images/4_filter_fuel.svg"
import mileage_pic from "../images/5_filter_mileage.svg"
import weight_pic from "../images/20_filter_total_weight.svg"
import carry_pic from "../images/21_filter_carrying_capacity.svg"
import cooling from "../images/22_filter_cooling.svg"
import axles_pic from "../images/23_filter_number_of_axles.svg"
import engine_pic from "../images/6_filter_engine_size.svg"
import power_pic from "../images/7_filter_engine_power.svg"
import trans_pic from "../images/8_filter_transmission.svg"
import drive_pic from "../images/9_filter_drive.svg"
import color_pic from "../images/11_filter_color.svg"
import seats_pic from "../images/12_filter_seats.svg"
import doors_pic from "../images/13_filter_doors.svg"
import env_pic from "../images/10_filter_enviroment.svg"
import srtfltr from "../images/24_filter_other_details.svg"
import publish from "../images/61_publish_listing.svg"
import back from "../images/back.svg"
import car_pic from "../images/54_car_images.svg"
import car_credits from "../images/38_56_buy_credits.svg"
import Ask_Question from "../images/Tooltip_Round_Finish_New_Listing.svg"
import truck from "../images/truck.svg"
import motorcycle from "../images/motorcycle.svg"
import car from "../images/car.svg"
import van from "../images/van.svg"
import { BASE_URL, lan } from '../config'
import { toast } from 'react-toastify'
import $ from "jquery";
import wheel1 from "../images/64_2_wheel.svg"
import wheel2 from "../images/65_4_wheel.svg"
import crcoin from "../images/37_credits.svg"
import axios from 'axios'
import { getPostData, getUserData, setAddListStatus, setModal, setMode, setloading } from '../Redux/ActionCreater'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import MultiSelect from './MultiSelect'
import Resizer from "react-image-file-resizer";
import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';
export default function AddListing() {
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [modalNo, setmodalNo] = useState(1);
    const [credit, setCredit] = useState("");
    let [formData, setFormData] = useState({
        carType: "",
        subType: "",
        price: "",
        make: "",
        model: "",
        selling_rent: "",
        new_used: "",
        year: "",
        fuel: "",
        mileage: "",
        size: "",
        power: "",
        transmission: "",
        drive: "",
        env: "",
        color: "",
        seats: "",
        doors: "",
        axles: "",
        assign_credit: 0,
        image: [],
        name: "",
        description: "",
        other_details: [],
        weight: "",
        capacity: "",
        cool: "",
        auto_credit: false

    });
    let [years, setYears] = useState();
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [price, setPrice] = useState([]);
    const [color, setColor] = useState([]);
    const [year, setYear] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [mileage, setMileage] = useState([]);
    const [engineSize, setEngineSize] = useState([]);
    const [power, setPower] = useState([]);
    const [tranmission, setTransmission] = useState([]);
    const [drive, setDrive] = useState([]);
    const [seats, setSeats] = useState([]);
    const [doors, setDoors] = useState([]);
    const [enviroment, setEnviroment] = useState([]);
    const [weight, setWeight] = useState([]);
    const [carry, setCarry] = useState([]);
    const [cool, setCool] = useState([]);
    const [axles, setAxles] = useState([]);
    const [city, setCity] = useState([]);
    const [preData, setPreData] = useState([]);
    const [otherDetails, setOtherDetails] = useState([]);
    const multiselectRef = useRef(null);


    const { userData } = useSelector(
        (state) => ({
            userData: state.dataReducer.userData,
        }),
        shallowEqual
    );

    const fetchData = async (type) => {
        try {
            await axios({
                url: BASE_URL + "/api/get-pre-value",
                method: "POST",
                data: {
                    type: type
                }
            }).then(res => {
                if (res.data.status === 200) {
                    setPreData(res.data.data)
                    setOtherDetails(res.data.other_details)
                    setMake(res.data.makes_data)
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const fetchModels = async (id) => {
        try {
            await axios({
                url: BASE_URL + "/api/get-models",
                method: "POST",
                data: { make_id: id }
            }).then(res => {
                if (res.data.status === 200) {
                    setModel(res.data.data);

                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    // useEffect(()=>{
    //     fetchData();
    // },[])
    useEffect(() => {
        if (price.length == 0) {


            let preValue = {}
            for (let i = 0; i < preData.length; i++) {
                let key = preData[i].slug

                preValue = { ...preValue, [key]: preData[i].preValue }
            }
            // preData.length>0&&preData.map(item=>{
            //     preValue={...preValue,[item.slug]:item.preValue}
            // // })
            // preValue.make&&setMake([...preValue.make])
            // preValue.model&&setModel([...preValue.model])
            preValue.price && setPrice([...preValue.price].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.color && setColor([...preValue.color])
            preValue.year && setYears([...preValue.year].sort(function (a, b) { return Number(b.value)-Number(a.value)}))
            preValue.fuel && setFuel([...preValue.fuel])
            preValue.mileage && setMileage([...preValue.mileage].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_size && setEngineSize([...preValue.engine_size].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_power && setPower([...preValue.engine_power].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.engine_power && setTransmission([...preValue.transmission])
            preValue.drive && setDrive([...preValue.drive])
            preValue.seats && setSeats([...preValue.seats].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.doors && setDoors([...preValue.doors].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.enviroment && setEnviroment([...preValue.enviroment])
            preValue.total_weight && setWeight([...preValue.total_weight].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.carrying_capcity && setCarry([...preValue.carrying_capcity].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
            preValue.cooling && setCool([...preValue.cooling])
            preValue.number_of_axles && setAxles([...preValue.number_of_axles].sort(function (a, b) { return Number(a.value) - Number(b.value) }))
        }

    }, [preData])
    let clickMakeModal = (e) => {
        e.preventDefault();
        if (formData.subType === "" && formData.make == "") {
            return
            toast.error("Please choose make!");
        }
        if (formData.subType === "" && formData.model == "") {
            return
            toast.error("Please choose model!");
        }
        if (formData.price == "") {
            return
            toast.error("Please enter price!");
        }

        if (formData.selling_rent == "") {
            return
            toast.error("Please choose selling or rent!");
        }
        if (formData.new_used == "") {
            return
            // toast.error("Please choose new or used !");
        }
        $("#makemodal").prop('disabled', false);
        $("#makemodal").click();
        $("#makemodal").prop('disabled', true);

    }

    let clickYearModal = (e) => {
        e.preventDefault();
        if (formData.year == "") {
            return
            toast.error("Please choose year!");
        }
        if (formData.subType === "" && formData.fuel == "") {
            return
            toast.error("Please choose fuel!");
        }
        if (formData.subType !== "" && formData.cool == "") {
            return
            toast.error("Please choose fuel!");
        }
        if (formData.mileage == "") {
            return
            toast.error("Please enter mileage!");
        }
        $("#yearmodal").prop('disabled', false);
        $("#yearmodal").click();
        $("#yearmodal").prop('disabled', true);

    }

    let clickEngineModal = (e) => {
        e.preventDefault();

        if (formData.size == "") {
            return
            toast.error("Please choose engine size!");
        }
        if (formData.power == "") {
            return
            toast.error("Please enter engine power!");
        }
        if (formData.subType !== "" && formData.transmission == "") {
            return
            toast.error("Please enter engine power!");
        }
        $("#enginemodal").prop('disabled', false);
        $("#enginemodal").click();
        $("#enginemodal").prop('disabled', true);
    }

    let clickEnvModal = (e) => {
        e.preventDefault();
        if (formData.transmission == "") {
            return
            toast.error("Please choose transmission!");
        }
        if (formData.subType === "" && formData.drive == "") {
            return
            toast.error("Please choose drive!");
        } if (formData.subType === "" && formData.env == "") {
            return
            toast.error("Please choose enviroment!");
        }
        $("#envmodal").prop('disabled', false);
        $("#envmodal").click();
        $("#envmodal").prop('disabled', true);
    }

    let clickColorModal = (e) => {
        e.preventDefault();
        if (formData.color == "") {
            return
            toast.error("Please choose color!");
        }
        if (formData.seats == "") {
            return
            toast.error("Please choose seats!");
        } if (formData.subType === "" && formData.doors == "") {
            return
            toast.error("Please choose doors!");
        }
        $("#colormodal").prop('disabled', false);
        $("#colormodal").click();
        $("#colormodal").prop('disabled', true);
    }


    let clickCarryModal = (e) => {
        e.preventDefault();
        if (formData.capacity == "") {
            return
            toast.error("Please choose color!");
        }
        if (formData.weight == "") {
            return
            toast.error("Please choose seats!");
        }
        if (formData.carType === "truck" && formData.axles == "") {
            return
            toast.error("Please choose seats!");
        }
        $("#carrymodal").prop('disabled', false);
        $("#carrymodal").click();
        $("#carrymodal").prop('disabled', true);
    }

    let clickImageModal = (e) => {
        e.preventDefault();
        if (formData.image.length === 0) {
            return
            toast.error("Please choose image!");
        }
        // if(formData.image.length<7){
        //     return toast.error("Please choose minimum 7 images!");
        // }
        if (formData.image.length > 15) {
            return toast.error("Please choose maximum 15 images!");
        }
        $("#imagemodal").prop('disabled', false);
        $("#imagemodal").click();
        $("#imagemodal").prop('disabled', true);
    }

    let deleteImg = (url) => {
        let arr = images;
        let arrImg = [...formData.image];
        let idx = arr.indexOf(url)
        if (idx > -1) {
            arr.splice(idx, 1);
            arrImg.splice(idx, 1);
            setImages([...arr]);
            setFormData({ ...formData, image: [...arrImg] })
        }
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(images);
        let arrImg = [...formData.image];

        const [reorderedItem] = items.splice(result.source.index, 1);
        const [reorderedImg] = arrImg.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        arrImg.splice(result.destination.index, 0, reorderedImg);

        setImages(items);
        setFormData({ ...formData, image: [...arrImg] })
    }

    let clickNameModal = (e) => {
        e.preventDefault();
        if (formData.name == "") {
            return
            toast.error("Please enter name!");
        }
        if (formData.description == "") {
            return
            toast.error("Please enter detail description!");
        }
        $("#namemodal").prop('disabled', false);
        $("#namemodal").click();
        $("#namemodal").prop('disabled', true);
    }

    let clickType = (type) => {
        setMake([])
        setModel([])
        fetchData(type)
        setFormData({
            carType: type,
            subType: "",
            price: "",
            make: "",
            model: "",
            selling_rent: "",
            new_used: "",
            year: "",
            fuel: "",
            mileage: "",
            size: "",
            power: "",
            transmission: "",
            drive: "",
            env: "",
            color: "",
            seats: "",
            doors: "",
            axles: "",
            assign_credit: "",
            image: [],
            other_details: [],
            name: "",
            description: "",
            weight: "",
            capacity: "",
            cool: "",
            auto_credit: false

        })
        $(".checkedRadio").prop('checked', false);

    }

    const handleMultipleImages = async (evnt) => {
        let fileArr = []
        const selectedFIles = [];
        const targetFiles = evnt.target.files;
        const targetFilesObject = [...targetFiles];
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1400,
            useWebWorker: true,
            fileType: 'image/webp'
        }

        targetFilesObject.map(async (file) => {
            // const compressedFile = await imageCompression(file, options);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            // console.log(compressedFile);
            // selectedFIles.push(URL.createObjectURL(compressedFile));
            // setImg([...selectedFIles])

            // selectedFIles.push(URL.createObjectURL(compressedFile))
            // setImages(selectedFIles);
            // fileArr.push(new File([compressedFile], compressedFile.name+".webp",{type:'image/webp'}))
            // setFormData({ ...formData, image: fileArr })

            new Compressor(file, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                minWidth: 1400,
                maxWidth: 1600,
                maxHeight: 1600,
                convertTypes: 'image/webp',
                convertSize: 1500000,
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server. 

                    selectedFIles.push(URL.createObjectURL(compressedResult))
                    setImages(selectedFIles);
                    fileArr.push(new File([compressedResult], compressedResult.name + ".webp", { type: 'image/webp' }))
                    setFormData({ ...formData, image: fileArr })
                    console.log(compressedResult, new File([compressedResult], compressedResult.name + ".webp", { type: 'image/webp' }));
                },
            });


        })


        // for(let i=0;i<evnt.target.files.length;i++){
        //     var fileInput = false;
        //     if (evnt.target.files[i]) {
        //       fileInput = true;
        //     }
        //     if (fileInput) {
        //       try {
        //         await Resizer.imageFileResizer(
        //           evnt.target.files[i],
        //           1600,
        //           1600,
        //           "webp",
        //           20,
        //           0,
        //           (uri) => {
        //             console.log(uri);
        //             selectedFIles.push(URL.createObjectURL(uri))
        //             setImages(selectedFIles);
        //             fileArr.push(uri)
        //             setFormData({ ...formData, image: fileArr })
        //           },
        //           "file",
        //           200,
        //           200
        //         );
        //       } catch (err) {
        //         console.log(err);
        //       }
        //     }
        //   }


        // targetFilesObject.map((file) => {

        //     return selectedFIles.push(URL.createObjectURL(file))
        // })
        // setImages(selectedFIles);
    }

    const logOut = () => {
        // setUserData("");
        $(".customRadio input[type=checkbox]").prop("checked", false);
        $("#submitmodal").prop('disabled', false);
        $("#submitmodal").click();
        $("#submitmodal").prop('disabled', true);

        dispatch(setMode(""));
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.error(lan.login_continue_msg);
        dispatch(getPostData({ preData: [] }));
        navigate("/")
        dispatch(setModal(false))
        dispatch(setloading(false));
        dispatch(setAddListStatus(false))
    }

    let otherDetailsClick = (item) => {
        let index = formData.other_details.indexOf(item.slug)
        if (index > -1) {
            let arr = [...formData.other_details]
            arr.splice(index, 1)
            setFormData({ ...formData, other_details: arr })
        } else {
            let arr = [...formData.other_details]
            arr.push(item.slug)
            setFormData({ ...formData, other_details: arr })
        }
    }

    let clickDetailsModal = (e) => {
        e.preventDefault();
        if (formData.other_details.length === 0) {
            return toast.error("Please choose alteast one!");
        }
        $("#detailsmodal").prop('disabled', false);
        $("#detailsmodal").click();
        $("#detailsmodal").prop('disabled', true);
    }



    let submitHandle = async (e) => {
        try {
            e.preventDefault()
            let login_data = JSON.parse(localStorage.getItem("login_data"));
            let token = localStorage.getItem("access_token");
            let data = new FormData();

            data.append("type", formData.carType)
            data.append("subtype", formData.subType)
            data.append("price", formData.price)
            data.append("make", formData.make)
            data.append("model", formData.model)
            data.append("year", formData.year)
            data.append("fuel", formData.fuel)
            data.append("mileage", formData.mileage)
            data.append("engine_size", formData.size)
            data.append("engine_power", formData.power)
            data.append("transmission", formData.transmission)
            data.append("drive", formData.drive)
            data.append("total_weight", formData.weight)
            data.append("carrying_capcity", formData.capacity)
            // data.append("number_of_axles", formData.axles)
            data.append("enviroment", formData.env)
            data.append("color", formData.color)
            data.append("seats", formData.seats)
            data.append("doors", formData.doors)
            data.append("cooling", formData.cool)
            data.append("name", formData.name)
            data.append("description", formData.description)
            data.append("number_of_axles", formData.axles)
            data.append("assign_credit", formData.assign_credit)
            data.append("selling_rent", formData.selling_rent)
            data.append("new_used", formData.new_used)
            data.append("auto_credit", formData.auto_credit ? formData.auto_credit : false);
            data.append("user_id", login_data._id)
            data.append("city_id", login_data.city_id)
            data.append("user_phone", login_data.phone)
            // data.append("other_details", formData.other_details)
            if (formData.other_details.length > 0) {
                for (let i = 0; i < formData.other_details.length; i++) {
                    data.append("other_details", formData.other_details[i])
                }
            }
            let arr = [...formData.image];
            if (arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    data.append("image", formData.image[i])
                }
            }


            await axios({
                url: BASE_URL + "/api/add-listing",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: data,
            }).then(res => {
                if (res.data.status === 401) {
                    logOut()
                }
                if (res.data.status === 200) {

                    $(".customRadio input[type=checkbox]").prop("checked", false);
                    $("#submitmodal").prop('disabled', false);
                    $("#submitmodal").click();
                    $("#submitmodal").prop('disabled', true);
                    // navigate("/");
                    console.log(res.data, res.data.data)
                    if (res.data.data.status === true) {
                        navigate("/" + login_data.unique_id + "/aktivni-oglasi");
                        localStorage.setItem("login_data", JSON.stringify({ ...userData,activeCredit:userData.activeCredit + formData.assign_credit, total_assign_credit: userData.total_assign_credit + formData.assign_credit, total_credit: userData.total_credit - formData.assign_credit }))
                    } else if (res.data.data.status === false) {
                        navigate("/" + login_data.unique_id + "/neaktivni-oglasi")
                    } else {
                        navigate("/")
                    }
                    setImages([])
                    setFormData({
                        carType: "",
                        subType: "",
                        price: "",
                        make: "",
                        model: "",
                        selling_rent: "",
                        new_used: "",
                        year: "",
                        fuel: "",
                        mileage: "",
                        size: "",
                        power: "",
                        transmission: "",
                        drive: "",
                        env: "",
                        color: "",
                        seats: "",
                        doors: "",
                        axles: "",
                        assign_credit: "",
                        image: [],
                        name: "",
                        description: "",
                        other_details: [],
                        weight: "",
                        capacity: "",
                        cool: "",
                        auto_credit: false

                    })
                    dispatch(setModal(false))
                    dispatch(setloading(false))
                    dispatch(setAddListStatus(false));
                    toast.success("Oglas uspješno objavljen!");
                } else {
                    dispatch(setModal(false))
                    dispatch(setloading(false))
                    dispatch(setAddListStatus(false))
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status);
            dispatch(setloading(false));
            dispatch(setAddListStatus(false))
            if (error.response.status === 401) {
                logOut()
            } else {
                toast.error("Greška u sistemu! Molimo da kontaktirate podršku.")
            }
        }
    }
    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(images);
        // let arrImg=[...formData.image];

        const [reorderedItem] = items.splice(result.source.index, 1);
        // const [reorderedImg] = arrImg.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        // arrImg.splice(result.destination.index, 0, reorderedImg);

        setImages(items);
        // setFormData({ ...formData, image: [...arrImg] })
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                $(".rghttootipa").removeClass("showTooltip");
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [])

    return (
        <>
            <div class="modal dashbordModal ovrfHide fade" id="sortmodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            {/* <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-dismiss="modal" aria-label="Close">
                                    <img src={back} />
                                </a>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal2">
                                    <img src={back} />
                                </a>
                            </div> */}

                            <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(setModal(false)) }}>
                                <img src={Close_indow} alt="" />
                                <img src={Close} alt="" />
                            </button>

                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup pb-0 scrlpop">
                                <div className='fstcalink '>
                                    <div className='carselct' onClick={() => { clickType("car") }} data-bs-toggle="modal" data-bs-target="#addmodal2">
                                        <figure><img src={car} /></figure>
                                    </div>
                                    <div className='carselct' onClick={() => { clickType("truck") }} data-bs-toggle="modal" data-bs-target="#addmodal2">
                                        <figure><img src={truck} /></figure>
                                    </div>
                                    <div className='carselct' onClick={() => { clickType("van") }} data-bs-toggle="modal" data-bs-target="#addmodal2">
                                        <figure><img src={van} /></figure>
                                    </div>
                                    <div className='carselct' onClick={() => { clickType("motorcycle") }} data-bs-toggle="modal" data-bs-target="#addmodal10">
                                        <figure><img className='mtrcImg' src={motorcycle} /></figure>
                                    </div>
                                </div>

                                {/* <div className='whtxt'>
                                    WHAT ARE YOU SELLING?
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#sortmodal3">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "1/10" : "1/9" : "1/6"}</span>
                                </div>

                                <a style={{ opacity: formData.subType === "" ? (formData.make !== "" && formData.model !== "" && formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? 1 : 0.33) : (formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? 1 : 0.33) }} disabled={formData.subType === "" ? (formData.make !== "" && formData.model !== "" && formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? false : true) : (formData.price !== "" && formData.selling_rent !== "" && formData.new_used !== "" ? false : true)} href='' onClick={(e) => { clickMakeModal(e) }}
                                >
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button style={{ opacity: 0 }} id="makemodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal3"   ></button>
                        <div class="modal-body">
                            <div class={formData.subType === "" ? "selrtypoup fusClor fusClor14 scrlpop" : "selrtypoup fusClor fusClor14 scrlpop motoTypeByke"}>
                                <div class="seller-type-pop">

                                    <div class="row">
                                        {formData.subType === "" && <div class="col-lg-6 col-md-6 col-12">
                                            <div class="twordobx">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={stear2} alt />
                                                    </div>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <Multiselect
                                                            className="deskSelect"
                                                            displayValue="make_name"
                                                            // ref={multiselectRef}
                                                            showArrow={true}
                                                            emptyRecordMsg="Nema dostupnih opcija"
                                                            avoidHighlightFirstOption={true}
                                                            style={{
                                                                chips: {
                                                                    background: "transparent",
                                                                    fontSize: "22px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    color: "#000"
                                                                },
                                                                searchBox: {
                                                                    justifyContent: "center",
                                                                    color: "#989898"
                                                                },
                                                                inputField: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                },
                                                                option: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                }
                                                            }
                                                            }
                                                            singleSelect={true}
                                                            // customArrow={<img src={drp} />}
                                                            // onSearch={function noRefCheck(){console.log("sear")}}
                                                            onSelect={function noRefCheck(selectedList, selectedItem) {
                                                                fetchModels(selectedItem._id); setFormData({ ...formData, make: selectedItem._id, model: "" }); setModel([]); multiselectRef.current.resetSelectedValues()
                                                            }}
                                                            placeholder={lan.choose_make}
                                                            options={make}
                                                        />
                                                        <select style={{ color: formData.make !== "" ? "#000" : "#989898" }} className='' value={formData.make} onChange={(e) => { setFormData({ ...formData, make: e.target.value, model: "" });; fetchModels(e.target.value) }} class="chosemk mobileSelect">
                                                            <option hidden disabled value="">{lan.choose_make}</option>
                                                            {make && make.map(i => {
                                                                return <option value={i._id}>{i.make_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div class="chosefrm" style={{ opacity: formData.make ? 1 : 0.33 }}>
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"model_name"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={model}
                                                            data={"model"}
                                                            val={"_id"}
                                                            placeholder={lan.choose_model}
                                                            multiselectRef={multiselectRef}
                                                        />
                                                        <select style={{ color: formData.model !== "" ? "#000" : "#989898" }} disabled={formData.make ? false : true} value={formData.model} onChange={(e) => { setFormData({ ...formData, model: e.target.value }) }} class="chosemk mobileSelect">
                                                            <option hidden disabled value="">{lan.choose_model}</option>
                                                            {model && model.map(i => {
                                                                return <option value={i._id}>{i.model_name}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="twordobx padbtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={pricepic} />
                                                    </div>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter} cijenu (KM)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, price: e.target.value }) } }} value={formData.price}
                                                            className='iputprice' type='tel' placeholder={`${lan.enter} cijenu (KM)`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                                <div className={formData.subType === "" ? 'chkbox-optin' : 'chkbox-optin margTop'}>
                                    <div className='twochkline'>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, selling_rent: "Selling" }) }}>
                                            <input type="radio" id="test1" name="checkbox-group" class="ship_address checkedRadio" value="1" />
                                            <label for="test1">{lan.selling}</label>
                                        </div>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, selling_rent: "Rent" }) }}>
                                            <input type="radio" id="test2" name="checkbox-group" class="ship_address checkedRadio" value="1" />
                                            <label for="test2" style={{ justifyContent: "flex-end" }}>{lan.rent}</label>
                                        </div>
                                    </div>
                                    <div className='twochkline'>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, new_used: "Korišteno" }) }}>
                                            <input type="radio" id="test3" name="checkbox-group1" class="ship_address1 checkedRadio" value="1" />
                                            <label for="test3" >{lan.used}</label>
                                        </div>
                                        <div class="customRadio" onClick={() => { setFormData({ ...formData, new_used: "Novo" }) }}>
                                            <input type="radio" id="test4" name="checkbox-group1" class="ship_address1 checkedRadio" value="1" />
                                            <label for="test4" style={{ justifyContent: "flex-end" }}>{lan.new}</label>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal dashbordModal ovrfHide fade" id="addmodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal2">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "2/10" : "2/9" : "2/6"}</span>
                                </div>
                                <a style={{ opacity: formData.year !== "" && formData.mileage !== "" ? formData.subType === "" ? formData.fuel === "" ? 0.33 : 1 : formData.cool === "" ? 0.33 : 1 : 0.33 }} disabled={formData.year !== "" && formData.mileage !== "" ? formData.subType === "" ? formData.fuel === "" ? true : false : formData.cool === "" ? true : false : true} href='' onClick={(e) => { clickYearModal(e) }}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="yearmodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal4"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop flxtrt">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={year_pic} alt />
                                                    </div>
                                                    <p>{lan.year}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        {/* <Multiselect
                                                            displayValue="value"
                                                            // ref={multiselectRef}
                                                            showArrow={true}
                                                            avoidHighlightFirstOption={true}
                                                            style={{
                                                                chips: {
                                                                    background: "transparent",
                                                                    fontSize: "22px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    color: "#000"
                                                                },
                                                                searchBox: {
                                                                    justifyContent: "center",
                                                                    color: "#989898"
                                                                },
                                                                inputField: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                },
                                                                option: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                }
                                                            }
                                                            }
                                                            singleSelect={true}
                                                            // customArrow={<img src={drp} />}
                                                            // onSearch={function noRefCheck(){console.log("sear")}}
                                                            onSelect={function noRefCheck(selectedList, selectedItem) {
                                                                setFormData({ ...formData, year: selectedItem.value })
                                                            }}
                                                            placeholder={lan.choose}
                                                            options={years}
                                                        /> */}
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={years}
                                                            data={"year"}
                                                        />
                                                        <select style={{ color: formData.year !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, year: e.target.value }) }} value={formData.year} class="chosemk mobileSelect">
                                                            <option value="" >{lan.choose}</option>
                                                            {years && years.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={fuel_pic} alt />
                                                    </div>
                                                    <p>{lan.fuel}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={fuel}
                                                            data={"fuel"}
                                                        />
                                                        <select style={{ color: formData.fuel !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, fuel: e.target.value }) }} value={formData.fuel} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {fuel && fuel.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {formData.subType !== "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={cooling} alt />
                                                    </div>
                                                    <p>{lan.COOLING}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={cool}
                                                            data={"cool"}
                                                        />
                                                        <select style={{ color: formData.cool !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, cool: e.target.value }) }} value={formData.cool} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {cool && cool.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={mileage_pic} alt />
                                                    </div>
                                                    <p>{lan.mileage}</p>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter} (km)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, mileage: e.target.value }) } }} value={formData.mileage}
                                                            class="chosemk2 iputprice" type='tel' placeholder={`${lan.enter} (km)`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content TopPading0">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal3">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "3/10" : "3/9" : "3/6"}</span>
                                </div>
                                <a style={{ opacity: formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? 0.33 : 1 : 0.33 }} disabled={formData.size !== "" && formData.power !== "" ? formData.subType !== "" && formData.transmission === "" ? true : false : true} href='javascript:void(0);' onClick={clickEngineModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="enginemodal" disabled data-bs-toggle="modal" data-bs-target={formData.subType === "" ? formData.carType === "truck" || formData.carType === "van" ? "#addmodal99" : "#addmodal5" : "#addmodal8"}   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop">
                                <div class="seller-type-pop fulebx">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={engine_pic} alt />
                                                    </div>
                                                    <p>{lan.size}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={engineSize}
                                                            data={"size"}
                                                        />
                                                        <select style={{ color: formData.size !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, size: e.target.value }) }} value={formData.size} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {engineSize && engineSize.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {formData.subType !== "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={trans_pic} alt />
                                                    </div>
                                                    <p>{lan.transmission}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={tranmission}
                                                            data={"transmission"}
                                                        />
                                                        <select style={{ color: formData.transmission !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, transmission: e.target.value }) }} value={formData.transmission} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {tranmission && tranmission.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={power_pic} alt />
                                                    </div>
                                                    <p>{lan.power}</p>
                                                    <div class="chosefrm">
                                                        <input
                                                            onFocus={(e) => { e.target.placeholder = "" }}
                                                            onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter} (kW)` }}
                                                            onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, power: e.target.value }) } }} value={formData.power}
                                                            class="chosemk2 iputprice" type='tel' placeholder={`${lan.enter} (kW)`} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal99" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content TopPading0">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal4">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>4/10</span>
                                </div>
                                <a style={{ opacity: (formData.capacity !== "" && formData.weight !== "") ? (formData.carType === "truck" && formData.axles === "") ? 0.33 : 1 : 0.33 }} disabled={(formData.capacity !== "" && formData.weight !== "") ? (formData.carType === "truck" && formData.axles !== "") ? false : true : true} href='javascript:void(0);' onClick={clickCarryModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="carrymodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal5"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop">
                                <div class="seller-type-pop fulebx">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={carry_pic} alt />
                                                    </div>
                                                    <p>{lan.capacity}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={carry}
                                                            data={"capacity"}
                                                        />
                                                        <select style={{ color: formData.capacity !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, capacity: e.target.value }) }} value={formData.capacity} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {carry && carry.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {(formData.carType === "truck" || formData.carType === "van") &&
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="twordobx yrfuel mrgnBtm">
                                                    <div class="seletfrm">
                                                        <div class="stear-icon">
                                                            <img src={weight_pic} alt />
                                                        </div>
                                                        <p>{lan.weight}</p>
                                                        <div class="chosefrm">
                                                            <i><img src={drp} /></i>
                                                            <MultiSelect
                                                                displayValue={"value"}
                                                                setFormData={setFormData}
                                                                formData={formData}
                                                                options={weight}
                                                                data={"weight"}
                                                            />
                                                            <select style={{ color: formData.weight !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, weight: e.target.value }) }} value={formData.weight} class="chosemk mobileSelect">
                                                                <option value="">{lan.choose}</option>
                                                                {weight && weight.map(i => {
                                                                    return <option value={i.value}>{i.value}</option>
                                                                })}
                                                            </select>
                                                            {/* <input
                                                        onFocus={(e) => { e.target.placeholder = "" }}
                                                        onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter}` }}
                                                        onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, weight: e.target.value }) } }} value={formData.weight}
                                                        class="chosemk2 iputprice" type='tel' placeholder={`${lan.enter}`} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {(formData.carType === "truck") &&
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="twordobx yrfuel mrgnBtm">
                                                    <div class="seletfrm">
                                                        <div class="stear-icon">
                                                            <img src={axles_pic} alt />
                                                        </div>
                                                        <p>{lan.axles}</p>
                                                        <div class="chosefrm">
                                                            <i><img src={drp} /></i>
                                                            <MultiSelect
                                                                displayValue={"value"}
                                                                setFormData={setFormData}
                                                                formData={formData}
                                                                options={axles}
                                                                data={"axles"}
                                                            />
                                                            <select style={{ color: formData.axles !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, axles: e.target.value }) }} value={formData.axles} class="chosemk mobileSelect">
                                                                <option value="">{lan.choose}</option>
                                                                {axles && axles.map(i => {
                                                                    return <option value={i.value}>{i.value}</option>
                                                                })}
                                                            </select>
                                                            {/* <input
                                                        onFocus={(e) => { e.target.placeholder = "" }}
                                                        onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.enter}` }}
                                                        onChange={(e) => { if (/^\d+$/.test(e.target.value) || e.target.value == "") { setFormData({ ...formData, weight: e.target.value }) } }} value={formData.weight}
                                                        class="chosemk2 iputprice" type='tel' placeholder={`${lan.enter}`} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}



                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal  dashbordModal ovrfHide fade" id="addmodal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content TopPading0">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target={formData.carType === "truck" || formData.carType === "van" ? "#addmodal99" : "#addmodal4"}>
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "5/10" : "4/9"}</span>
                                </div>
                                <a style={{ opacity: (formData.transmission !== "" && formData.env !== "") ? (formData.subType === "" && (formData.drive === "" || formData.env === "")) ? 0.33 : 1 : 0.33 }} disabled={(formData.transmission !== "" && formData.env !== "") ? (formData.subType === "" && (formData.drive === "" || formData.env === "")) ? true : false : true} href='javascript:void(0);' onClick={clickEnvModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button className='abstbtn' style={{ opacity: 0 }} id="envmodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal6"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop flxtrt">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={trans_pic} alt />
                                                    </div>
                                                    <p>{lan.transmission}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            className="deskSelect"
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={tranmission}
                                                            data={"transmission"}
                                                        />
                                                        <select style={{ color: formData.transmission !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, transmission: e.target.value }) }} value={formData.transmission} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {tranmission && tranmission.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={drive_pic} alt />
                                                    </div>
                                                    <p>{lan.drive}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={drive}
                                                            data={"drive"}
                                                        />
                                                        <select style={{ color: formData.drive !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, drive: e.target.value }) }} value={formData.drive} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {drive && drive.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={env_pic} alt />
                                                    </div>
                                                    <p>{lan.env}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={enviroment}
                                                            data={"env"}
                                                        />
                                                        <select style={{ color: formData.env !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, env: e.target.value }) }} value={formData.env} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {enviroment && enviroment.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}


                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal5">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "6/10" : "5/9"}</span>
                                </div>
                                <a style={{ opacity: (formData.color !== "" && formData.seats !== "") ? (formData.subType === "" && formData.doors === "") ? 0.33 : 1 : 0.33 }} disabled={formData.color !== "" && formData.doors !== "" && formData.seats !== "" ? false : true} href='' onClick={clickColorModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button className='abstbtn' style={{ opacity: 0 }} id="colormodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal7"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop flxtrt">
                                <div class="seller-type-pop fulebx">

                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={color_pic} alt />
                                                    </div>
                                                    <p>{lan.color}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={color}
                                                            data={"color"}
                                                        />
                                                        <select style={{ color: formData.color !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, color: e.target.value }) }} value={formData.color} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {color && color.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={seats_pic} alt />
                                                    </div>
                                                    <p>{lan.seats}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={seats}
                                                            data={"seats"}
                                                        />
                                                        <select style={{ color: formData.seats !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, seats: e.target.value }) }} value={formData.seats} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {seats && seats.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.subType === "" && <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel mrgnBtm">
                                                <div class="seletfrm">
                                                    <div class="stear-icon">
                                                        <img src={doors_pic} alt />
                                                    </div>
                                                    <p>{lan.doors}</p>
                                                    <div class="chosefrm">
                                                        <i><img src={drp} /></i>
                                                        <MultiSelect
                                                            displayValue={"value"}
                                                            setFormData={setFormData}
                                                            formData={formData}
                                                            options={doors}
                                                            data={"doors"}
                                                        />
                                                        <select style={{ color: formData.doors !== "" ? "#000" : "#989898" }} onChange={(e) => { setFormData({ ...formData, doors: e.target.value }) }} value={formData.doors} class="chosemk mobileSelect">
                                                            <option value="">{lan.choose}</option>
                                                            {doors && doors.map(i => {
                                                                return <option value={i.value}>{i.value}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}


                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal6">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{(formData.carType === "truck" || formData.carType === "van") ? "7/10" : "6/9"}</span>
                                </div>
                                <a style={{ opacity: formData.other_details?.length !== 0 ? 1 : 0.33 }} disabled={formData.other_details?.length === 0 ? false : true} href='' onClick={clickDetailsModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="detailsmodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal8"   ></button>

                        <div class="modal-body">
                            <div className="otherdetl-popup allrdiopop">

                                <div className="text-center">
                                    <figure>
                                        <img src={srtfltr} alt />
                                    </figure>
                                    <h4>{lan.details}</h4>
                                </div>
                                <div className="allopfiltr">
                                    <div className="row">
                                        {otherDetails && otherDetails.length > 0 && otherDetails.map((item, idx) => {
                                            return <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="customRadio ">
                                                    <input type="checkbox" onClick={() => otherDetailsClick(item)} name={"power" + idx} id={"option-0" + idx} />
                                                    <label htmlFor={"option-0" + idx} className={`option${idx} option-0${idx}`}>{item.name}</label>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target={formData.subType === "" ? "#addmodal7" : "#addmodal4"}>
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "8/10" : "7/9" : "4/6"}</span>
                                </div>
                                <a style={{ opacity: formData.image.length !== 0 ? 1 : 0.33 }} disabled={formData.image.length !== 0 ? false : true} href='' onClick={clickImageModal}>
                                    <img src={back} />
                                </a>
                                {/* <a style={{transform: "rotate(0deg)"}} href='' onClick={submitHandle} >
                                    <img src={publish} />
                                </a> */}
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="imagemodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal20"   ></button>

                        <div class="modal-body">
                            <div class="selrtypoup mblimgcbtr ">

                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_pic} alt />
                                        </figure>
                                        <h4>SLIKE VOZILA</h4>
                                    </div>

                                    <div className='uplodbtn'>
                                        <form encType="multipart/form-data">
                                            <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                        </form>
                                        <span className='upldlnk'>UČITAJ</span>
                                    </div>
                                </div>

                                <div className="row imageUpdatePic" >
                                    <div>
                                        {images[0] && <div className="cardpic row justify-content-center oldPic widthadj addmainimg">
                                            <div class="col-lg-6 editimage">
                                                <img src={images[0]} />
                                                {/* <form encType="multipart/form-data">
                                       <span class="deletePic editimage"><img src={pencil2} /></span>
                                           <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                       </form> */}
                                            </div>
                                        </div>}
                                        {images && images.length > 0 && <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="images">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="row justify-content-center" style={{ margin: "auto", maxWidth: "500px" }}>
                                                        {
                                                            images.map((url, idx) => {
                                                                return url && (
                                                                    <Draggable key={url} draggableId={url} index={idx}>
                                                                        {(provided) => (
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="col-lg-6 col-6">
                                                                                <div className="cardpic oldPic">
                                                                                    <span class="deletePic" onClick={() => { deleteImg(url) }} ><img src={Close} /></span>
                                                                                    <img src={url} />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>}
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal8">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "10/10" : "9/9" : "6/6"}</span>
                                </div>
                                <a style={{ transform: "rotate(0deg)" }} href='' onClick={(e) => { dispatch(setAddListStatus(true)); dispatch(setloading(true)); submitHandle(e) }} >
                                    <img src={publish} />
                                </a>
                            </div>
                        </div>
                        <button className='abstbtn' style={{ opacity: 0 }} id="submitmodal" disabled data-bs-dismiss="modal" aria-label="Close"></button>

                        <div class="modal-body">
                            <div class="selrtypoup scrlpop">

                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_credits} alt />
                                        </figure>
                                        <h4>AKTIVIRAJ OGLAS</h4>
                                        {/* <h4>{lan.add_credit}</h4> */}
                                    </div>
                                    <div className='frmdtabx'>
                                        <div class="frmData creaditfrom">
                                            {/* <i><img src="assets/images/Smartphone.png" alt="" /></i> */}
                                            <input id="credit"
                                                onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = `${lan.credits_amount}` }}
                                                value={credit}
                                                onChange={(e) => { if (/^[+]?\d+$/.test(e.target.value) || e.target.value === "") { setCredit(e.target.value) } }}
                                                type="tel" placeholder={lan.credits_amount} className='iputprice' />
                                            <div class="rghttootip rghttootipa" ref={wrapperRef}>
                                                <button class="tooltipIcon" onClick={(e) => { e.preventDefault(); $(".rghttootipa").toggleClass("showTooltip"); }}><img src={Ask_Question} /></button>
                                                <div class="rghttootipMsg">
                                                    <h4>INFO</h4>
                                                    <p>Možete objaviti oglas i bez dodavanja kredita. Isti će biti u NEAKTIVNIM OGLASIMA, a potencijalnim kupcima će postati vidljiv tek kada ga aktivirate dodavanjem željenog iznosa kredita.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a href="#url" onClick={(e) => {
                                            e.preventDefault();
                                            let value = $("#credit").val();
                                            if (value === "") {
                                                return toast.error("Unesite iznos kredita!")
                                            }
                                            toast.success(lan.credit_add_succss)
                                            setFormData({ ...formData, assign_credit: value })
                                        }} class="assignbtn">{lan.assign}</a>
                                        <h6 className='upascred'>
                                            DODIJELJENO KREDITA: {formData.assign_credit ? formData.assign_credit : 0}</h6>
                                        <div className='automatic' >
                                            <div class="customCheckbox" >
                                                <input type="checkbox" id="test01" name="checkbox-group" class="ship_address" value="1" />
                                                <label for="test01" onClick={() => { setFormData({ ...formData, auto_credit: !formData.auto_credit }) }}>{lan.automatic}</label>
                                                {/* {formData.auto_credit&&<span>1 {lan.month} = 1 {lan.credit}</span>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='popbtomtxt'>
                                <p><img src={crcoin} /> STANJE KREDITA: {userData.total_credit}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="addmodal20" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal8">
                                    <img src={back} />
                                </a>
                                <div className="numberCount">
                                    <span>{formData.subType === "" ? (formData.carType === "truck" || formData.carType === "van") ? "9/10" : "8/9" : "5/6"}</span>
                                </div>
                                <a style={{ opacity: formData.name !== "" && formData.description !== "" ? 1 : 0.33 }} disabled={formData.description !== "" && formData.name !== "" ? false : true} href='' onClick={clickNameModal}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <button class="abstbtn" style={{ opacity: 0 }} id="namemodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal9"></button>

                        <div class="modal-body">

                            <div class="selrtypoup scrlpop">

                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={stear2} alt />
                                        </figure>
                                        {/* <h4>DETALJI OGLASA</h4> */}
                                    </div>
                                    <div className='frmdtabx'>
                                        <div class="frmData">
                                            <textarea onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "NASLOV OGLASA" }}
                                                onChange={(e) => { if (e.target.value.length <= 50) { setFormData({ ...formData, name: e.target.value }) } }} value={formData.name} type="text" placeholder="NASLOV OGLASA" className='iputprice lstNameIn'>

                                            </textarea>
                                            {/* <input onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "NASLOV OGLASA" }}
                                                onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }} value={formData.name} type="text" placeholder="NASLOV OGLASA" className='iputprice'/> */}
                                        </div>
                                        <div class="frmData">
                                            <textarea onFocus={(e) => { e.target.placeholder = "" }}
                                                onBlur={(e) => { if (e.target.value === "") e.target.placeholder = "DETALJAN OPIS" }}
                                                onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} value={formData.description} placeholder='DETALJAN OPIS'></textarea>
                                        </div>



                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal dashbordModal ovrfHide fade" id="addmodal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#sortmodal3">
                                    <img src={back} />
                                </a>
                                <a href='javascript:void(0);' style={{ opacity: "0.33" }}>
                                    <img src={back} />
                                </a>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup fusClor14 scrlpop">
                                <div class="seller-type-pop fulebx">

                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel" data-bs-toggle="modal" data-bs-target="#addmodal2" onClick={() => { setFormData({ ...formData, subType: "atv" }) }}>
                                                <div class="seletfrm mblMrgn">
                                                    <div class="stear-icon">
                                                        <img src={wheel2} alt />
                                                    </div>
                                                    <p>ATV - QUAD</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="twordobx yrfuel" data-bs-toggle="modal" data-bs-target="#addmodal2" onClick={() => { setFormData({ ...formData, subType: "2_wheeler" }) }}>
                                                <div class="seletfrm mblMrgn">
                                                    <div class="stear-icon">
                                                        <img src={wheel1} alt />
                                                    </div>
                                                    <p>MOTOCIKL</p>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                                {/* <div class="okbtn">
                                    <a href="" class="oksbmit">ok</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal dashbordModal ovrfHide fade" id="addmodal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal6">
                                    <img src={back} />
                                </a>
                                <button style={{ opacity: 0 }} id="imagemodal" disabled data-bs-toggle="modal" data-bs-target="#addmodal20"   ></button>
                                <a href='' onClick={clickImageModal}>
                                    <img src={back} />
                                </a>
                                {/* <a style={{transform: "rotate(0deg)"}} href='' onClick={submitHandle} >
                                    <img src={publish} />
                                </a> */}
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup scrlpop">
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_pic} alt />
                                        </figure>
                                        <h4>SLIKE VOZILA</h4>
                                    </div>

                                    <div className='uplodbtn'>
                                        <form encType="multipart/form-data">
                                            <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                        </form>
                                        <span className='upldlnk'>UČITAZ</span>
                                    </div>
                                </div>

                                <div className="row" style={{ margin: "auto", maxWidth: "500px" }}>
                                    {/* {
                                        images.map((url) => {
                                            return url && (
                                                <div className="col-lg-4 col-6">
                                                    <div className="cardpic">
                                                        <img src={url} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    } */}
                                    <div>
                                        {images[0] && <div className="cardpic oldPic widthadj ">
                                            <div class="col-lg-6 editimage">
                                                <img src={images[0]} />
                                                {/* <form encType="multipart/form-data">
                                       <span class="deletePic editimage"><img src={pencil2} /></span>
                                           <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                       </form> */}
                                            </div>
                                        </div>}
                                        {images && images.length > 0 && <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="images">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="row" style={{ margin: "auto", maxWidth: "500px" }}>
                                                        {
                                                            images.map((url, idx) => {
                                                                return url && (
                                                                    <Draggable
                                                                        // disableInteractiveElementBlocking={idx === 0 ? true : false} isDragDisabled={idx === 0 ? true : false} 
                                                                        key={url} draggableId={url} index={idx}>
                                                                        {(provided) => (
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="col-lg-6 col-6">
                                                                                <div className="cardpic oldPic">
                                                                                    <span class="deletePic" onClick={() => deleteImg(url)}><img src={Close} /></span>
                                                                                    <img src={url} />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>}
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal dashbordModal ovrfHide fade" id="addmodal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <a href='javascript:void(0);' data-bs-toggle="modal" data-bs-target="#addmodal9">
                                    <img src={back} />
                                </a>

                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup scrlpop">
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={crcoin} alt />
                                        </figure>
                                        <h4>BUYING CREDITS</h4>
                                    </div>


                                </div>

                                <div className='allcredit-sec'>
                                    <div className='credtbx'>
                                        <div className='creditinner'>
                                            <h4>10</h4>
                                            <span>{lan.credit}</span>
                                            <hr />
                                            <p>9 KM</p>
                                        </div>
                                        <a href=''>{lan.buy}</a>
                                    </div>

                                    <div className='credtbx'>
                                        <div className='creditinner'>
                                            <h4>60</h4>
                                            <span>{lan.credit}</span>
                                            <hr />
                                            <p>49 KM</p>
                                        </div>
                                        <a href=''>{lan.buy}</a>
                                    </div>

                                    <div className='credtbx'>
                                        <div className='creditinner'>
                                            <h4>130</h4>
                                            <span>{lan.credit}</span>
                                            <hr />
                                            <p>99 KM</p>
                                        </div>
                                        <a href=''>{lan.buy}</a>
                                    </div>





                                </div>


                                <div className='creditblnc'>
                                    <h5>{lan.credit_balance}:  9</h5>
                                    <h6>{lan.associated_credits}: 3</h6>
                                    <hr></hr>
                                    <p><strong>1 </strong> {lan.credit} = <strong>1 </strong> {lan.month} FOR <strong>1 </strong> VEHICLE</p>

                                </div>






                            </div>
                        </div>
                    </div>
                </div>
            </div>







        </>
    )
}
