import React, { useEffect, useState } from 'react'
import Sidebar from '../../partial/Sidebar'
import Arrow_Pointing_Left from "../../images/desktop_back.svg"
import Heart from "../../images/102_103_106_heart_saved_listing.svg"
import drctin from "../../images/104_directions.svg"
import Love from "../../images/heart_black.svg"
import Heart_white from "../../images/heart_white.svg"
import Close_indow from "../../images/Close_indow.png"
import Close from "../../images/close_01.svg"
import ExternalLink from "../../images/ExternalLink.png"
import pencil2 from "../../images/Pencil.svg"
import Header from '../../partial/Header'
import { useNavigate, useParams } from 'react-router-dom'
import SellerListing from './SellerListing';
import SideRight from './SideRight';
import axios from 'axios';
import $ from "jquery";
import { BASE_HREF, BASE_URL, lan } from '../../config';
import Fancybox from './FancyBox';
import UpdateSideRight from './UpdateSideRight';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPostData, getUserData, setCurrentProduct, setLandscape, setMode, setPreProduct, setloading } from '../../Redux/ActionCreater';
import { toast } from 'react-toastify';
import SoldModal from './SoldModal';
import { Markup } from 'interweave';

function Details() {
	let token = localStorage.getItem("access_token");
	const [data, setData] = useState({})
	const [stage, setStage] = useState("details")
	const [sellerPost, setSellerPost] = useState([])
	const [image, setImage] = useState([])
	const [wishlistData, setWishlistData] = useState({});
	const [saveDealerData, setSaveDealerData] = useState({});
	const [sellerIconData, setSellericonData] = useState({});
	// const [adData,setAdData]=useState([])
	const [wishlistCount, setWishlistCount] = useState("")
	const { id } = useParams();
	const dispatch = useDispatch();
	const { preProduct, userData } = useSelector(
		(state) => ({
			preProduct: state.dataReducer.preProduct,
			userData: state.dataReducer.userData,
		}),
		shallowEqual
	);

	const fetchData = async () => {
		try {
			dispatch(setloading(true))
			await axios({
				url: BASE_URL + "/api/vehicle/post/details",
				method: "POST",
				data: {
					post_id: id,
					"loggedin_user_id": JSON.parse(localStorage.getItem("login_data"))?._id
				}
			}).then(res => {
				if (res.data.status === 200) {
					setData(res.data.data);
					setSellerPost(res.data.same_seller_post)
					// setSellerPost(res.data.same_seller_post)
					// setImage([res.data.data.image]);
					setImage([res.data.data.image]);
					setWishlistData(res.data.wishlistData);
					setSaveDealerData(res.data.saveDealerData)
					setWishlistCount(res.data.wishlistCount);
					// setAdData(res.data.adData);
					setSellericonData(res.data.sellerIcon)
					setTimeout(() => {
						dispatch(setloading(false))
					}, 1000);
				}
			})
		} catch (error) {
			dispatch(setloading(false));
			navigate("/")
			console.log(error)
		}
	}
	const options = {
		loop: true,
		margin: 0,
		height: 600,
		items: 1,
		autoplay: false,
		autoplaySpeed: 1200,
		rewind: false,
		lazyLoad: true,
		// navRewind: true,
		autoplayTimeout: 6000,
		navSpeed: 2000,
		dragEndSpeed: 2000,
		dots: false,
		nav: false
	};
	const navigate = useNavigate();
	useEffect(() => {
		fetchData();
		setStage("details");
		$(".modal-backdrop").hide()
		$("body").css("overflow", "auto");
		$("body").removeClass("openMenu");
		$("body").removeClass("openFilter");
	}, [id])

	let markSold = async () => {
		try {
			await axios({
				url: BASE_URL + "/api/listing-mark-sold",
				method: "POST",
				headers: {
					authorization: token
				},
				data: { id: data._id },
			}).then(res => {
				if (res.data.status === 200) {
					// dispatch(setMode("finish"));
					localStorage.setItem('login_data', JSON.stringify(res.data.userData));
					dispatch(getUserData(res.data.userData));
					navigate("/" + data.user_id.unique_id + "/zavrseni-oglasi")
					$("sldcls").click();
					toast.success("Oglas uspješno završen!");
				} else {
					toast.error(res.data.message)
				}
			})

		} catch (error) {
			console.log(error.response.status)
			if (error.response.status === 401) {
				logOut()
			}
		}
	}


	const clickShow = (e) => {
		e.preventDefault();
		$("body").toggleClass("hideOverly");
	}

	const clickClose = (e) => {
		e.preventDefault();
		$("body").removeClass("hideOverly")
	}

	const clickMini = (e) => {
		e.preventDefault();
		$("body").removeClass("hideOverly");
	}

	const dateFormater = (date) => {
		const today = new Date(date);
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;

		const formattedToday = dd + '.' + mm + '.' + yyyy;
		return formattedToday
	}
	const logOut = () => {
		// setUserData("");
		dispatch(setMode(""));
		dispatch(getUserData(""));
		localStorage.removeItem("login_data");
		localStorage.removeItem("access_token");
		toast.success(lan.login_continue_msg);
		dispatch(getPostData({ preData: [] }));
		dispatch(setCurrentProduct(""));
		navigate("/");

		$(".btnClose").click();
	}

	const wishlistClick = async (type) => {
		let token = localStorage.getItem("access_token");
		if (token == null || token == "") {
			toast.dismiss();
			return toast.error(lan.login_continue_msg)
		}
		try {
			await axios({
				url: BASE_URL + "/api/wishlist/add-remove",
				method: "POST",
				headers: {
					authorization: token
				},
				data: {
					"post_id": data._id,
					"is_favourite": !wishlistData.is_favourite,

				},
			}).then(res => {
				if (res.data.status === 200) {
					setWishlistData({ ...wishlistData, is_favourite: !wishlistData.is_favourite })
					toast.dismiss();
					console.log(type)
					!type ? toast.success(lan.wishlist_add_msg) :
						toast.success(lan.wishlist_remove_msg);
				} else {
					toast.error(res.data.message);
				}
			})
		} catch (error) {
			console.log(error)
			if (error.response.status === 401) {
				logOut()
			}
		}
	}
	let clickDetailsBack = (e) => {
		e.preventDefault();
		if (preProduct.length === 0) {
			navigate("/");
		} else {
			
			let idx = preProduct.length - 1
			navigate(preProduct[idx])
			// navigate(`/post-details/${preProduct[idx]}`);
			let arr = preProduct;
			arr.splice(-1);
			dispatch(setPreProduct(arr))
		}
	}

	let clickSeler = () => {
		let arr = preProduct
		arr.push(`/${data.user_id.username}/${id}/${data.make.make_name}-${data.model.model_name}`)
		dispatch(setCurrentProduct(""));
		dispatch(setPreProduct(arr));
		navigate("/" + data?.user_id?.username);
	}

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [])

	return (
		<>
			<Header stage={stage} setStage={setStage} page={"details"} userdata={data?.user_id} />
			<Sidebar />
			{stage == "details" && <div class="delerDesktop">
				<div onClick={clickMini} class="miniOverly"></div>
				{data && <div class="listingHed">
					<div class="container">
						<div class="listingHedinr">
							<a href={`${BASE_HREF}/`} onClick={(e) => { clickDetailsBack(e) }}><img src={Arrow_Pointing_Left} alt="" /></a>
							<div>
								<h1>{data?.name}</h1>
								{data.sold_status &&<span class="ogcls">ZAVRŠEN OGLAS</span>}
								<strong>{data?.price} <em>KM</em></strong>
							</div>
						</div>
					</div>
				</div>}

				<div class="listingDtils">
					<div class="container">
						<div class="row">
							<div class="col-lg-8">
								<div class="listingDtilslft">
									<div class="listingDtilslftSldr">
									{data.selling_rent==="Rent"&&<h5 className='rentBtn detailsrentbtn'>RENT</h5>}
										{/* {data&&data.image&&JSON.parse(data.image).length>0 &&<OwlCarousel className='owl-theme' {...options}>
						{
							JSON.parse(data.image).map((item)=>{
								return <div class="item">
								<div class="carDetilsImge">
								<img src={`${BASE_URL}/uploads/post/${item}`} alt="" />
								</div>
							</div>
							})
						}
                        
                            </OwlCarousel>} */}

										{data && data.image && JSON.parse(data.image).length > 0 && <Fancybox
											options={{
												Carousel: {
													infinite: false,
													Navigation: false,
												},
												on: {
													close: (fancybox, slide) => {
														dispatch(setLandscape(true))
														console.log("first")
													},
												}

											}}
										>
											{
												JSON.parse(data.image).map((item, idx) => {
													return <a style={{ display: idx === 0 ? "block" : "none" }} data-fancybox="gallery" href={`${BASE_URL}/uploads/post/${item}`}>
														<img src={`${BASE_URL}/uploads/post/${item}`} width="200" height="150" />
													</a>

												})
											}

										</Fancybox>}
										<div className='whishCount'>
											<i><img src={Heart_white} /><span>{wishlistCount}</span></i>
										</div>
										<div className='imgCount'>
											<span>1/{data?.image && JSON.parse(data?.image).length}</span>
										</div>
									</div>
									<div class="detailedDescrip">
										<h4>{lan.detail_desc}</h4>
										<p><Markup content={data?.description} /></p>
									</div>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="listingDtilsRt">
									<div class="listingLst">
										<div class="showModal">
											<div class="modal-dialog">
												<div class="modal-content">
													<button onClick={clickClose} type="button" class="showModalClose">
														<img src={Close_indow} alt="" />
													</button>
													<div class="showModalLst">
														<div class="listingLstTop detailsAttri">
															<ul>
																{data?.other_details?.length > 0 && data?.other_details.map((item, idx) => {
																	return <li style={{ borderBottom: data?.other_details?.length % 2 !== 0 && idx == (data?.other_details?.length - 2) && "1px solid #989898" }}>
																		<em>{item.name}</em>

																	</li>
																})}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="listingLstTop">
											<ul>
												{data?.make&&<li>
													<em>{lan.make}</em>
													<b>{data?.make?.make_name}</b>
												</li>}
												{data?.model&&<li>
													<em>{lan.model}</em>
													<b>{data?.model?.model_name}</b>
												</li>}
												<li>
													<em>{lan.year}</em>
													<b>{data?.year}</b>
												</li>
												<li>
													<em>{lan.mileage}</em>
													<b>{data?.mileage} <span style={{ color: "#989898" }}>km</span></b>
												</li>
												{data?.engine_size && <li>
													<em>{lan.size}</em>
													<b>{data?.engine_size}</b>
												</li>}
												{data?.engine_power && <li>
													<em>{lan.power}</em>
													<b>{data?.engine_power} <span style={{ color: "#989898" }}>kW</span></b>
												</li>}
												{data?.transmission && <li>
													<em>{lan.transmission}</em>
													<b>{data?.transmission}</b>
												</li>}


												{data?.fuel && <li>
													<em>{lan.fuel}</em>
													<b>{data?.fuel}</b>
												</li>}
												{data?.carrying_capcity && <li>
													<em>{lan.capacity}</em>
													<b>{data?.carrying_capcity}</b>
												</li>}
												{data?.total_weight && <li>
													<em>{lan.weight}</em>
													<b>{data?.total_weight}</b>
												</li>}
												{data?.number_of_axles && <li>
													<em>{lan.axles}</em>
													<b>{data?.number_of_axles}</b>
												</li>}
												{data?.doors && <li>
													<em>{lan.doors}</em>
													<b>{data?.doors}</b>
												</li>}
												{data?.drive && <li>
													<em>{lan.drive}</em>
													<b>{data?.drive}</b>
												</li>}
												{data?.enviroment && <li>
													<em>{lan.env}</em>
													<b>{data?.enviroment}</b>
												</li>}
												{data?.color && <li>
													<em>{lan.color}</em>
													<b>{data?.color}</b>
												</li>}
												{data?.cooling && <li>
													<em>{lan.COOLING}</em>
													<b>{data?.cooling}</b>
												</li>}


												{data?.seats && <li>
													<em>{lan.seats}</em>
													<b>{data?.seats}</b>
												</li>}
												{data?.new_used && <li>
													<em>{lan.new_used}</em>
													<b>{data?.new_used}</b>
												</li>}
												{data?.location && <li>
													<em>{lan.location}</em>
													<b>{data?.location.city_name}</b>
												</li>}
											</ul>
										</div>
										<div class="listingLstBtm">
											<ul>
												<li>
													<em>{lan.date_create}</em>
													<b>{data?.createdAt && dateFormater(data?.createdAt)}</b>
												</li>
												<li>
													<em>{lan.seller_id}</em>
													<b>{data?.user_id?.unique_id}</b>
												</li>
												<li>
													<em>{lan.car_listing_id}</em>
													<b>{data?.list_id}</b>
												</li>
												{data?.color&&<li>
													<em>{lan.details}</em>
													<b><a href="" onClick={clickShow} class="ShowBtn">{lan.show}<i><img src={ExternalLink} alt="" /></i></a></b>
												</li>}
											</ul>
										</div>
									</div>
									<div class="listingDtilsRtbtm">
										<ul>
											<li style={{ cursor: "pointer" }} onClick={() => { wishlistClick(wishlistData?.is_favourite) }}>
												<i><img src={wishlistData?.is_favourite === true ? Love : Heart} alt="" /></i>
												<h5>{wishlistData?.is_favourite === true ? lan.listing_save : lan.SAVE_LIST}</h5>
											</li>
											<li style={{ cursor: "pointer" }} onClick={() => { navigate("/" + data?.user_id?.username); clickSeler() }}>
												{/* <i><img src={data?.user_id?.seller_type == "private_seller" ? Account_black : Frame2} alt="" /></i> */}
												<i><img style={{width:data?.user_id?.profile_pic?"150px":null}} src={data?.user_id?.profile_pic?`${BASE_URL}/uploads/user/profile_pic/${data?.user_id?.profile_pic}`:`${BASE_URL}/uploads/seller-icon/${sellerIconData.icon}`} alt="" /></i>
												{data?.user_id?.profile_pic?null:<h5>{data?.user_id?.username}</h5>}
											</li>

										</ul>
									</div>
								</div>
							</div>
						</div>
						{sellerPost.length > 1 && <div class="selerListinghed">
							<h3>{lan.see_other_list}</h3>
						</div>}
					</div>

				</div>

			</div>}
			{stage == "details" && <div class="delermobile">
				<div onClick={clickMini} class="miniOverly"></div>
				<div class="container">
					<div class="listingDtilslftSldr">
					{data.selling_rent==="Rent"&&<h5 className='rentBtn detailsrentbtn'>RENT</h5>}
						{userData && data?.user_id && !data?.sold_status && userData?._id === data?.user_id?._id && <a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#upmodal8" className='pnctbtn'><img src={pencil2} /></a>}
						{/* {data && data.image && JSON.parse(data.image).length > 0 && <OwlCarousel className='owl-theme' {...options}  >
							{JSON.parse(data.image).map(item => {
								return <div class="item">
									<div class="carDetilsImge">
										{item && <img src={`${BASE_URL}/uploads/post/${item}`} alt="" />}
									
									</div>
								</div>
							})}
							
						</OwlCarousel>} */}
						{data && data.image && JSON.parse(data.image).length > 0 && <Fancybox
							options={{
								Carousel: {
									infinite: false,
									Navigation: false,
								},
								on: {
									"close": (fancybox, slide) => {
										dispatch(setLandscape(true));
									},
								}
							}}
						>
							{
								JSON.parse(data.image).map((item, idx) => {
									return <a style={{ display: idx === 0 ? "block" : "none" }} data-fancybox="gallery" href={`${BASE_URL}/uploads/post/${item}`}>
										<img onClick={() => { dispatch(setLandscape(false)); }} src={`${BASE_URL}/uploads/post/${item}`} width="200" height="150" />
									</a>

								})
							}
						</Fancybox>}
						<div className='whishCount'>
							<i><img src={Heart_white} /><span>{wishlistCount}</span></i>
						</div>
						<div className='imgCount'>
							<span>1/{data?.image && JSON.parse(data?.image).length}</span>
						</div>
					</div>
				</div>
				<div class="listingHed">
					<div class="container">
						<div class="listingHedinr">
							<div style={{ justifyContent: "space-between" }}>
								<div style={{ padding: data?.name?.length > 54 ? "6px 0" : "0" }}>
									<h1>{data?.name}</h1></div>

								<strong>{data?.price} <em>KM</em></strong>
							</div>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="listingDtilsRtbtm">
						{data.sold_status ? <ul><li><div class="soldTag">ZAVRŠEN OGLAS</div></li>
						<li onClick={() => { clickSeler() }}>
							<i><img  src={data?.user_id?.profile_pic?`${BASE_URL}/uploads/user/profile_pic/${data?.user_id?.profile_pic}`:`${BASE_URL}/uploads/seller-icon/${sellerIconData.icon}`} alt="" /></i>
							{data?.user_id?.profile_pic?null:<h5>{data?.user_id?.username}</h5>}
						</li></ul> :
							<ul>
								{userData && data?.user_id && userData?._id === data?.user_id?._id ?
									<li data-bs-toggle="modal" data-bs-target="#soldModal" style={{ border: "none" }} >
										{/* <i><img src={handshke} alt="" /></i>
								<h5  style={{color:"#202629"}}>MARK AS SOLD</h5> */}
										<h5 className='actok' style={{ marginTop: 0 }}>ZAVRŠI OGLAS</h5>
									</li>
									: <li onClick={() => { clickSeler() }}>
										<i style={{width:"100%"}} ><img style={{width:data?.user_id?.profile_pic?"auto":null,maxHeight:"62px"}} src={data?.user_id?.profile_pic?`${BASE_URL}/uploads/user/profile_pic/${data?.user_id?.profile_pic}`:`${BASE_URL}/uploads/seller-icon/${sellerIconData.icon}`} alt="" /></i>
										{data?.user_id?.profile_pic?null:<h5>{data?.user_id?.username}</h5>}
									</li>}

								{userData && data?.user_id && userData?._id === data?.user_id?._id ?
									<li data-bs-toggle="modal" data-bs-target="#upcredit">
										<h5 className='actok' style={{ marginTop: 0, background: "#202629", color: "#F8B756" }}>{new Date(data.expiry_date) >= new Date() ? "DODIJELI KREDITE" : "AKTIVIRAJ OGLAS"}</h5>
									</li>
									:
									(data?.user_id?.seller_type !== "private_seller" ?
										<li>
											<ul>
												<li>
													<a href={data?.user_id?.direction_link} target="_blank"><i className='saveDelar'><img src={drctin} alt="" /></i></a>
													{/* <em>{lan.directions}</em> */}
												</li>
												<li onClick={() => { wishlistClick(wishlistData?.is_favourite) }}>
													<i><img src={wishlistData?.is_favourite === true ? Love : Heart} alt="" /></i>
													{/* <h5>{wishlistData?.is_favourite === true ? lan.listing_save : lan.SAVE_LIST}</h5> */}
												</li>
											</ul>
										</li>
										:

										<li onClick={() => { wishlistClick(wishlistData?.is_favourite) }}>
											<i><img src={wishlistData?.is_favourite === true ? Love : Heart} alt="" /></i>
											{/* <h5>{wishlistData?.is_favourite === true ? lan.listing_save : lan.SAVE_LIST}</h5> */}
										</li>)
								}


							</ul>}
					</div>
					<div class="listingLst">
						<div class="showModal">
							<div class="modal-dialog">
								<div class="modal-content">
									<button type="button" onClick={clickClose} class="showModalClose">
										<img src={Close} alt="" />
									</button>
									<div class="showModalLst">
										<div class="listingLstTop detailsAttri">
											<ul>
												{data?.other_details?.length > 0 && data?.other_details.map((item, idx) => {
													return <li style={{ borderBottom: data?.other_details?.length % 2 !== 0 && idx == (data?.other_details?.length - 2) && "1px solid #989898" }}>
														<em>{item.name}</em>
													</li>
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="listingLstTop ">
							{userData && data?.user_id && !data?.sold_status && userData?._id === data?.user_id?._id && <a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#upmodal2" className='pnctbtn'><img src={pencil2} /></a>}
							<ul>
								{data?.make&&<li>
									<em>{lan.make}</em>
									<b>{data?.make?.make_name}</b>
								</li>}
								{data?.make&&<li>
									<em>{lan.model}</em>
									<b>{data?.model?.model_name}</b>
								</li>}
								<li>
									<em>{lan.year}</em>
									<b>{data?.year}</b>
								</li>
								<li>
									<em>{lan.mileage}</em>
									<b>{data?.mileage} <span style={{ color: "#989898" }}>km</span></b>
								</li>
								{data?.engine_size && <li>
									<em>{lan.size}</em>
									<b>{data?.engine_size}</b>
								</li>}
								{data?.engine_power && <li>
									<em>{lan.power}</em>
									<b>{data?.engine_power} <span style={{ color: "#989898" }}>kW</span></b>
								</li>}
								{data?.transmission && <li>
									<em>{lan.transmission}</em>
									<b>{data?.transmission}</b>
								</li>}


								{data?.fuel && <li>
									<em>{lan.fuel}</em>
									<b>{data?.fuel}</b>
								</li>}
								{data?.carrying_capcity && <li>
									<em>{lan.capacity}</em>
									<b>{data?.carrying_capcity}</b>
								</li>}
								{data?.total_weight && <li>
									<em>{lan.weight}</em>
									<b>{data?.total_weight}</b>
								</li>}
								{data?.number_of_axles && <li>
									<em>{lan.axles}</em>
									<b>{data?.number_of_axles}</b>
								</li>}
								{data?.doors && <li>
									<em>{lan.doors}</em>
									<b>{data?.doors}</b>
								</li>}
								{data?.drive && <li>
									<em>{lan.drive}</em>
									<b>{data?.drive}</b>
								</li>}
								{data?.enviroment && <li>
									<em>{lan.env}</em>
									<b>{data?.enviroment}</b>
								</li>}
								{data?.color&&<li>
									<em>{lan.color}</em>
									<b>{data?.color}</b>
								</li>}
								{data?.cooling && <li>
									<em>{lan.COOLING}</em>
									<b>{data?.cooling}</b>
								</li>}


								{data?.seats && <li>
									<em>{lan.seats}</em>
									<b>{data?.seats}</b>
								</li>}
								{data?.new_used && <li>
									<em>{lan.new_used}</em>
									<b>{data?.new_used}</b>
								</li>}
								{data?.location && <li>
									<em>{lan.location}</em>
									<b>{data?.location.city_name}</b>
								</li>}
							</ul>
						</div>
						<div class="listingLstBtm">
							<ul>
								<li>
									<em>{lan.date_create}</em>
									<b>{data?.createdAt && dateFormater(data?.createdAt)}</b>
								</li>
								<li>
									<em>{lan.seller_id}</em>
									<b>{data?.user_id?.unique_id}</b>
								</li>
								<li>
									<em>{lan.car_listing_id}</em>
									<b>{data?.list_id}</b>
								</li>
								{data?.color&&<li>
									<em>{lan.details}</em>
									<b><a href="" onClick={clickShow} class="ShowBtn">{lan.show}<i><img src={ExternalLink} alt="" /></i></a></b>
								</li>}
							</ul>
						</div>
					</div>

					<div class="detailedDescrip">
						{userData && data?.user_id && !data?.sold_status && userData?._id === data?.user_id?._id && <a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#upmodal20" className='pnctbtn'><img src={pencil2} /></a>}
						<h4>{lan.detail_desc}</h4>
						<p><Markup content={data?.description} /></p>
					</div>
					{/* {data?.user_id && JSON.parse(localStorage.getItem("login_data"))?._id === data?.user_id?._id &&<div class="ctrgyList">
						<ul>
							<li data-bs-toggle="modal" data-bs-target="#upmodal2">
								<i><img src={edtlist} alt="" /></i>
								<em>EDIT DETAILS</em>
							</li>
							<li data-bs-toggle="modal" data-bs-target="#upmodal8">
								<i><img src={iglry} alt="" /></i>
								<em>EDIT IMAGES</em>
							</li>

						</ul></div>}
					{data?.user_id && JSON.parse(localStorage.getItem("login_data"))?._id === data?.user_id?._id &&<div class="ctrgyList">
						<ul>
							<li data-bs-toggle="modal" data-bs-target="#upmodal20">
								<i><img src={pencil} alt="" /></i>
								<em>EDIT DESCRIPTION</em>
							</li>
							<li style={{ border: "none" }} onClick={markSold}>
								<i><img src={handshke} alt="" /></i>
								<em>MARK AS SOLD</em>
							</li>

						</ul></div>} */}
					{sellerPost.length > 1 && <div class="oglasitext">{lan.see_other_list}:</div>}

				</div>

			</div>}

			{stage == "details" && sellerPost.length > 1 && <SellerListing data={data} setStage={setStage} setSellerPost={setSellerPost} id={id} fetchData={fetchData} sellerPost={sellerPost} />}
			{stage == "details" && sellerPost.length > 1 && <div class="viditext" style={{ cursor: "pointer" }} onClick={() => { clickSeler() }}>{lan.see_all_list}...</div>}
			{data?.user_id && JSON.parse(localStorage.getItem("login_data"))?._id !== data?.user_id?._id && <SideRight clickSeler={clickSeler} saveDealerData={saveDealerData} setSaveDealerData={setSaveDealerData} stage={stage} setStage={setStage} data={data} />}
			{data?.user_id && JSON.parse(localStorage.getItem("login_data"))?._id === data?.user_id?._id && <UpdateSideRight stage={stage} setStage={setStage} data={data} setData={setData} />}
			{/* {stage == "seller" && <SellerDetails adData={adData} sellerPost={sellerPost} setStage={setStage} data={data} />} */}

			<SoldModal markSold={markSold} />
		</>
	)
}

export default Details