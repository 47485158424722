import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Sidebar from '../../partial/Sidebar';
import Header from '../../partial/Header';
import { BASE_URL, lan } from '../../config';
import userSmall from "../../images/private_seller_grey.svg"
import { useNavigate } from 'react-router-dom';
import smallDealer from "../../images/car_dealer_grey.svg"
import yr from "../../images/yr.svg"
import filter_clear_all from "../../images/filter_clear_all.svg"
import dsl from "../../images/dsl.svg"
import milg from "../../images/milg.svg"
import auto from "../../images/auto.svg"
import lcn from "../../images/lcn.svg"
import $ from "jquery";
import { useDispatch } from 'react-redux';
import { getPostData, getUserData, setMode, setloading } from '../../Redux/ActionCreater';
import { toast } from 'react-toastify';
function WishList() {
    const [listData,setListData]=useState([]);
    let token = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut=()=>{
      // setUserData("");
      dispatch(setloading(false))
      dispatch(getUserData(""));
      localStorage.removeItem("login_data");
      localStorage.removeItem("access_token");
      toast.error(lan.login_continue_msg);
      dispatch(getPostData({preData:[]}));
      dispatch(setMode(""))
      navigate("/")
  }


  const wishlistClick = async (e, item) => {
		e.preventDefault();
		let token = localStorage.getItem("access_token");
		if (token == null || token == "") {
			toast.dismiss();
			return toast.error(lan.login_continue_msg)
		}
		try {
			await axios({
				url: BASE_URL + "/api/wishlist/add-remove",
				method: "POST",
				headers: {
					authorization: token
				},
				data: {
					"post_id": item._id,
					"is_favourite": false,

				},
			}).then(res => {
				if (res.data.status === 200) {
					listData.map((i, idx) => {
            console.log(i,i.post_id?._id === item._id)
						if (i.post_id?._id === item._id) {
							let arr = listData
              arr.splice(idx, 1);
							setListData([...arr])
						}
					})
          toast.dismiss()
						toast.success(lan.wishlist_remove_msg);
				} else {
					toast.error(res.data.message);
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

    const fetchData=async()=>{
        try {
          dispatch(setloading(true))
            await axios({
                url: BASE_URL + "/api/wishlist",
                method:"post",
                headers: {
                    authorization: token
                },
                data: {
					"loggedin_user_id": JSON.parse(localStorage.getItem("login_data"))?._id
				}
            }).then(res=>{
                if(res.data.status===200){
                    setListData(res.data.data);
                    setTimeout(() => {	
                      dispatch(setloading(false))
                    }, 1000);
                }else{
                  dispatch(setloading(false));
                }
            })
        } catch (error) {
          console.log(error.response.status)
          dispatch(setloading(false));
          if(error.response.status===401){
            logOut()
          }
        }
    }

    let productNavigate=(item)=>{
      // dispatch(setCurrentProduct(item._id));
      // navigate(`/${item.userData.username}/${item.list_id}/${item.make?.make_name}-${item.model?.model_name}`)
      if(item.make){
        // navigate(`/${item.user_id.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
        navigate(`/${item.post_id.user_id.username}/${item.post_id.list_id}/${item.post_id.make.make_name}-${item.post_id.model.model_name}`)
      }else{
        // navigate(`/${item.user_id.username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
        navigate(`/${item.post_id.user_id.username}/${item.post_id.list_id}/${item.post_id.name.replaceAll(" ","-")}`)
      }
    }

    useEffect(()=>{
        fetchData()
        $(".modal-backdrop").hide()
        $("body").css("overflow","auto");
        $("body").removeClass("openMenu");
        $("body").removeClass("openFilter");
    },[])
  return (
    <>
    <Header page={"wishlist"} />
    <Sidebar/>
    <div class="listingDtils CAR_DEALERSldrSec mblsvlsthdr">
				<div class="container">
    <div class="activeListing">
			<div class="col-lg-12 col-md-6" style={{margin:"auto"}}>
				<div class="ACTIVE_LISTINGS" style={{background:"#fff",color:"#ccc"}}>
					<h3 style={{color:"#000"}}>{lan.saved_listings}</h3>
					
					{/* <i><img style={{maxWidth:"22px"}} src={finish?ylwCircle:status?FilledCircle:rd} alt="" /></i> */}
				</div>
			</div>
			{/* <div class="col-lg-4 col-md-6 col-6">
				<div class="selerListinghed">
					<h3>{lan.finish_listings}</h3>
                    <i><img src={FilledCircle} alt="" /></i>
				</div>
			</div> */}
		</div>
</div>
</div>

<div className="productSec list-view-filter">
  <div className="container">
    <div className="row">
        {listData&&listData.length>0&&listData.map((item)=>{
            return item.post_id && <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="productbx">
              <div className="productbxtop">
                <div className="productbxtopimg">
                  <a href="#url" className="userSmall"><img src={item.post_id.user_id.seller_type==="private_seller"?userSmall:smallDealer} alt /></a>
                  {item.post_id?.image&&JSON.parse(item.post_id?.image).length>0&&<img onClick={(e)=>{e.preventDefault();productNavigate(item)}} src={`${BASE_URL}/uploads/post/${JSON.parse(item.post_id.image)[0]}`} alt="" />}
                  <a href="" onClick={(e)=>{ wishlistClick(e,item.post_id)}} class="grayHeart"><img src={filter_clear_all} alt="" /></a>
                  {item.post_id?.selling_rent==="Rent"&&<h5 className='rentBtn'>RENT</h5>}
                  {item.post_id?.new_used==="Novo"&&<h5 className='rentBtn newBtn'>NOVO</h5>}
                </div>
                <div className="productbxtoprtlst">
                <ul>
                <li><span><img src={yr}/></span> {item.post_id?.year}</li>
												<li><span><img src={dsl}/></span> {item.post_id?.fuel}</li>
												<li><span><img src={milg}/></span> {item.post_id?.mileage}</li>
												<li className='trnmbl'><span><img src={auto}/></span> {item.post_id?.transmission}</li>
												<li><span><img src={lcn}/></span> {item.post_id.location?.city_name}</li>
						</ul>
                </div>
              </div>
              <div class="productbxbtm" onClick={()=>{productNavigate(item)}}>
					<h3>{item.post_id.name}</h3>
					<strong><span>KM</span> {item.post_id.price}</strong>
				</div>
            </div>
          </div>
        })}

{listData&&listData.length===0&&<div class="viditext" style={{marginTop:"50px",fontWeight:"500"}} >{lan.no_data}...</div>}
    </div>
  </div>
</div>
    </>
  )
}

export default WishList