import React, { useState } from 'react'
import { BASE_URL, lan } from '../config'
import Close_indow from "../images/Close_indow.png"
import CloseTwo from "../images/close_01.svg"
import axios from 'axios'
import { shallowEqual, useSelector } from 'react-redux'
import { getPostData, getUserData, setModal, setMode } from '../Redux/ActionCreater'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import $ from "jquery";
import Paymentmodal from './Paymentmodal'
function CreditModal() {
    let [payment,setPayment]=useState("");
    let [credit,setCredit]=useState("");
    let [load, setLoad] = useState(false);
    let token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData } = useSelector(
        (state) => ({
            userData: state.dataReducer.userData,
        }),
        shallowEqual
    );
    const handlePayment = async (e) => { 
        try {
            e.preventDefault();
          // Make an API call to the Node.js server to initiate the hosted checkout
          const response = await axios.post(BASE_URL + "/api/test", { amount:100 });
    console.log(response)
          // Redirect the user to the hosted checkout page
        //   window.location.href = response.request.responseURL;
        } catch (error) {
          console.error('Error:', error);
        }
      };
      const logOut = () => {
        // setUserData("");
        dispatch(setMode(""));
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.error(lan.login_continue_msg);
        dispatch(getPostData({ preData: [] }));
        navigate("/");
        $(".payCls").click();
    }

      let authCheck=async(e)=>{
        try {
            await axios({
                url: BASE_URL + "/api/auth-check",
                method: "GET",
                headers: {
                    authorization: token
                }
            }).then(res=>{
                console.log(res.data.status)
            })
        } catch (error) {
            console.log(error.response.status)
            if (error.response.status === 401) {
                logOut()
            }
        }
      }
    
  return (
<>
    <div class="modal dashbordModal fade" style={{overflow:"hidden"}} id="addmodal23" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{dispatch(setModal(false));}}>
                                <img src={Close_indow} alt="" />
                                <img src={CloseTwo} alt="" />
                               
                            </button>
            </div>
            <div class="modal-body">
                <div class="selrtypoup mblcred">
                    {/* <div className="otherdetl-popup allrdiopop">
                        <div className="text-center">
                            <figure>
                                <img src={crcoin} alt />
                            </figure>
                            <h4>BUYING CREDITS</h4>
                        </div>


                    </div> */}

                    <div className='allcredit-sec'>
                        <div className='credtbx'>
                            <div className='creditinner'>
                                <h4>10</h4>
                                <span>{lan.credit}</span>
                                <hr />
                                <p>10 KM</p>
                            </div>
                            <a href='' onClick={(e)=>{e.preventDefault();authCheck();dispatch(setModal(true));setCredit("10");setPayment(5);setLoad(true)}}   data-bs-toggle="modal" data-bs-target="#paymentmodal">{lan.buy}</a>
                            
                        </div>

                        <div className='credtbx'>
                            <div className='creditinner'>
                                <h4>60</h4>
                                <span>{lan.credit}</span>
                                <hr />
                                <p>49 KM</p>
                            </div>
                            <a href='' onClick={(e)=>{e.preventDefault();authCheck();dispatch(setModal(true));setCredit("49");setPayment(25);setLoad(true)}}  data-bs-toggle="modal" data-bs-target="#paymentmodal">{lan.buy}</a>
                        </div>

                        <div className='credtbx'>
                            <div className='creditinner'>
                                <h4>130</h4>
                                <span>{lan.credit}</span>
                                <hr />
                                <p>99 KM</p>
                            </div>
                            <a href='' onClick={(e)=>{e.preventDefault();authCheck();dispatch(setModal(true));setCredit("99");setPayment(50);setLoad(true)}}   data-bs-toggle="modal" data-bs-target="#paymentmodal">{lan.buy}</a>
                        </div>





                    </div>


                    <div className='creditblnc'>
                        <h5>{lan.credit_balance}:  {userData.total_credit}</h5>
                        <h6>{lan.associated_credits}: {userData.activeCredit}</h6>
                        <hr></hr>
                        <p><strong>1 </strong> kredit = <strong>1 </strong> {lan.month.toLowerCase()} za <strong>1 </strong> vozilo</p>

                    </div>






                </div>
            </div>
        </div>
    </div>
</div>
<Paymentmodal load={load} payment={payment} credit={credit} setLoad={setLoad} />
</>

  )
}

export default CreditModal