import React, { useEffect, useState } from 'react'
import { BASE_URL, lan } from '../../config';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPostData, getUserData, setCurrentProduct, setMode, setloading } from '../../Redux/ActionCreater';
import FilledCircle from "../../images/Filled Circle green.svg"
import ylwCircle from "../../images/Filled Circle yellow.svg"
import rd from "../../images/Filled Circle red.svg"
import userSmall from "../../images/private_seller_grey.svg"
import grayHeart from "../../images/heart_grey.svg"
import colorHeert from "../../images/heart_y.svg"
import smallDealer from "../../images/car_dealer_grey.svg"
import yr from "../../images/yr.svg"
import dsl from "../../images/dsl.svg"
import milg from "../../images/milg.svg"
import auto from "../../images/auto.svg"
import lcn from "../../images/lcn.svg"

function ActiveListings({status,finish}) {
    let [data,setData]=useState([]);
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut=()=>{
        // setUserData("");
        dispatch(setloading(false))
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.error(lan.login_continue_msg);
        dispatch(getPostData({preData:[]}));
        dispatch(setMode(""))
        navigate("/")
    }

    let fetchData=async()=>{
        try {
          dispatch(setloading(true))
            var config = {
                method: "POST",
				headers: {
					authorization: token
				},
				data: {
                    status,
                    sold_status:finish,
                    loggedin_user_id:login_data._id
                },
                url: `${BASE_URL}/api/active-inactive-list`,
            };
            await axios(config)
            .then(function (res) {
                if (res.data.status === 401) {
                  
                    logOut();
                    return false;
                }
                if (res.data.status === 200) {
                    setData(res.data.data)
                    setTimeout(() => {	
                      dispatch(setloading(false))
                    }, 1000);
                } else {
                  dispatch(setloading(false))
                    toast.dismiss();
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            console.log(error.response.status)
            dispatch(setloading(false))
            if(error.response.status===401){
              logOut()
            }
        }
    }


    let productNavigate=(item)=>{
      // dispatch(setCurrentProduct(item._id));
      // navigate(`/${item.userData.username}/${item.list_id}/${item.make?.make_name}-${item.model?.model_name}`)
      if(item.make){
        navigate(`/${item.userData.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
      }else{
        navigate(`/${item.userData.username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
      }
    }

    useEffect(()=>{
        fetchData();
    },[])
  return (
    <>
    	<div class="listingDtils CAR_DEALERSldrSec activeInactive">
				<div class="container">
    <div class="activeListing">
			<div class="col-lg-11 col-md-6" style={{margin:"auto",width:"100%"}}>
				<div class="ACTIVE_LISTINGS" style={{background:"#fff",color:"#ccc"}}>
					{status==true&&finish===false&&<h3 style={{color:"#000"}}>{lan.active_listings}</h3>}
					{status==false&&finish===false&&<h3 style={{color:"#000"}}>{lan.inactive_listings}</h3>}
					{status==false&&finish===true&&<h3 style={{color:"#000"}}>{lan.finish_listings}</h3>}
          <i>{finish?<svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="10" r="9" fill="#F8B756" />
                                                    </svg>:status?<svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="10" r="9" fill="green" />
                                                    </svg>:<svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="10" r="9" fill="red" />
                                                    </svg>}</i>
					{/* <i><img style={{maxWidth:"22px"}} src={finish?ylwCircle:status?<svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="9" r="6" fill="#F8B756" />
                                                    </svg>:rd} alt="" /></i> */}
				</div>
			</div>
			{/* <div class="col-lg-4 col-md-6 col-6">
				<div class="selerListinghed">
					<h3>{lan.finish_listings}</h3>
                    <i><img src={FilledCircle} alt="" /></i>
				</div>
			</div> */}
		</div>
</div>
</div>

<div className="productSec list-view-filter noProduct">
  <div className="container">
    <div className="row">
        {data&&data.length>0&&data.map((item)=>{
            return <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="productbx">
              <div className="productbxtop">
                <div className="productbxtopimg">
                  <a href="#url" className="userSmall"><img src={item.userData.seller_type==="private_seller"?userSmall:smallDealer} alt /></a>
                  {item.image&&JSON.parse(item.image).length>0&&<img onClick={(e)=>{e.preventDefault();productNavigate(item)}} src={`${BASE_URL}/uploads/post/${JSON.parse(item.image)[0]}`} alt="" />}
                  <a href="" onClick={(e)=>{ e.preventDefault();}} class="grayHeart">{item.wishlistData?.is_favourite?<img src={colorHeert} alt="" />:<img src={grayHeart} alt="" />}</a>
                  {item.selling_rent==="Rent"&&<h5 className='rentBtn'>RENT</h5>}
                  {item.new_used==="Novo"&&<h5 className='rentBtn newBtn'>NOVO</h5>}
                </div>
                <div className="productbxtoprtlst" onClick={(e)=>{e.preventDefault();productNavigate(item)}} >
                <ul>
                <li><span><img src={yr}/></span> {item.year}</li>
												<li><span><img src={dsl}/></span> {item.fuel?item.fuel:"BENZIN"}</li>
												<li><span><img src={milg}/></span> {item.mileage}</li>
												<li className='trnmbl'><span><img src={auto}/></span> {item.transmission}</li>
												<li className={item.cityData?.city_name.split(" ")[0].length>10?"smallCity":"bigCity"}><span><img src={lcn}/></span> {item.cityData?.city_name}</li>
						</ul>
                </div>
              </div>
              <div class="productbxbtm" onClick={()=>{productNavigate(item)}}>
					<h3>{item.name}</h3>
					<strong><span>KM</span> {item.price}</strong>
				</div>
            </div>
          </div>
        })}
        {data&&data.length===0&&<div class="viditext" style={{marginTop:"50px",fontWeight:"500"}} >{lan.no_data}...</div>}
    </div>
  </div>
</div>
    </>
  )
}

export default ActiveListings